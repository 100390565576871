// // PropTypes & HOOKS
// import PropTypes from 'prop-types'

// // FORMIK HOOK
// import { Formik, Form, Field, ErrorMessage } from 'formik'
// import * as Yup from 'yup'

// // CONTEXTS
// import { ModalContext } from 'contexts/ModalContext'
// import { useContext } from 'react'

// // ASSETS
// import { AiOutlineSave, AiOutlineDelete } from 'react-icons/ai'

// const TeachersFormDetail = ({ fields, handleSubmit, handleDelete, isNew }) => {
//   // Modal confirmación eliminación:
//   const { openModal } = useContext(ModalContext)
//   const handleDeleteModal = (id) => {
//     const modalData = {
//       id,
//       handleDelete,
//       type: 'DELETE'
//     }

//     openModal(modalData)
//   }

//   return (
//     <>
//       <Formik
//         enableReinitialize={true}
//         initialValues={{ ...fields }}
//         onSubmit={(values) => {
//           handleSubmit(values)
//         }}
//       >
//         {({
//           values,
//           errors,
//           touched,
//           handleBlur,
//           setFieldValue,
//           submitForm
//         }) => (
//           <Form className="flex flex-col w-full space-y-4">
//             <div className="grid grid-cols-12 grid-rows-6 gap-6 p-4 m-0 border border-gray-300 shadow rounded-sm-sm print:p-0 bg-gray-50 print:bg-white print:border-0 print:shadow-none">
//               {/* NOM */}
//               <div title="NOM" className="col-span-6 row-span-1">
//                 <div className="flex flex-row items-center mb-3">
//                   <label
//                     className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
//                     htmlFor="NOM"
//                   >
//                     Nom
//                   </label>

//                   <ErrorMessage
//                     name="NOM"
//                     render={(message) => (
//                       <span className="text-sm font-bold leading-4 text-red-600">
//                         {message}
//                       </span>
//                     )}
//                   />
//                 </div>

//                 <Field
//                   className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
//                 ${
//                   touched.NOM && errors.NOM
//                     ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
//                     : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
//                 }`}
//                   name="NOM"
//                   type="text"
//                   placeholder="Escriure..."
//                 />
//               </div>

//               {/* COGNOM */}
//               <div title="COGNOM" className="col-span-6 row-span-1">
//                 <div className="flex flex-row items-center mb-3">
//                   <label
//                     className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
//                     htmlFor="COGNOM"
//                   >
//                     Cognom
//                   </label>

//                   <ErrorMessage
//                     name="COGNOM"
//                     render={(message) => (
//                       <span className="text-sm font-bold leading-4 text-red-600">
//                         {message}
//                       </span>
//                     )}
//                   />
//                 </div>

//                 <Field
//                   className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
//                 ${
//                   touched.COGNOM && errors.COGNOM
//                     ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
//                     : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
//                 }`}
//                   name="COGNOM"
//                   type="text"
//                   placeholder="Escriure..."
//                 />
//               </div>

//               {/* ID_ESCOLA */}
//               <div title="ID_ESCOLA" className="col-span-6 row-span-1">
//                 <div className="flex flex-row items-center mb-3">
//                   <label
//                     className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
//                     htmlFor="ID_ESCOLA"
//                   >
//                     Escola
//                   </label>

//                   <ErrorMessage
//                     name="ID_ESCOLA"
//                     render={(message) => (
//                       <span className="text-sm font-bold leading-4 text-red-600">
//                         {message}
//                       </span>
//                     )}
//                   />
//                 </div>

//                 <Field
//                   className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
//                 ${
//                   touched.ID_ESCOLA && errors.ID_ESCOLA
//                     ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
//                     : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
//                 }`}
//                   name="ID_ESCOLA"
//                   type="text"
//                   placeholder="Escriure..."
//                 />
//               </div>
//             </div>

//             <div className="grid w-full grid-cols-12 pt-4 m-0 gap-y-4">
//               <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
//                 <button
//                   alt="GUARDAR"
//                   title="GUARDAR"
//                   className="flex flex-row items-center justify-center w-full col-span-1 p-2 text-white transition duration-300 bg-green-600 border border-green-700 rounded-sm-sm hover:border-green-300 hover:bg-indigo-200 hover:text-green-700 font-ms-semi"
//                   type="submit"
//                 >
//                   <AiOutlineSave size={25} className="mr-2" />
//                   <p className="text-base font-semibold">Guardar</p>
//                 </button>
//               </div>

//               <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
//                 {!isNew && (
//                   <button
//                     alt="ELIMINAR"
//                     title="ELIMINAR"
//                     className="flex flex-row items-center justify-center w-full p-2 text-white transition duration-300 bg-red-600 border border-red-700 rounded-sm-sm hover:border-red-300 hover:bg-red-200 hover:text-red-700 font-ms-semi"
//                     onClick={() => {
//                       handleDeleteModal(values.ID_PROFESSOR)
//                     }}
//                     type="button"
//                   >
//                     <AiOutlineDelete size={25} className="mr-2" />
//                     <p className="text-base font-semibold">Eliminar</p>
//                   </button>
//                 )}
//               </div>
//             </div>
//           </Form>
//         )}
//       </Formik>
//     </>
//   )
// }

// TeachersFormDetail.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
//   handleDelete: PropTypes.func.isRequired,
//   fields: PropTypes.object,
//   isNew: PropTypes.bool
// }

// export default TeachersFormDetail



























// import PropTypes from 'prop-types'
// import { Formik, Form, Field, ErrorMessage } from 'formik'
// import * as Yup from 'yup'
// import { ModalContext } from 'contexts/ModalContext'
// import { useContext, useEffect, useState } from 'react'
// import { AiOutlineSave, AiOutlineDelete } from 'react-icons/ai'
// import axios from 'axios'
// import AdminFormDetail from 'forms/Admin/AdminFormDetail'

// const TeachersFormDetail = ({ fields, handleSubmit, handleDelete, isNew }) => {
//   const { openModal } = useContext(ModalContext)
//   const handleDeleteModal = (id) => {
//     const modalData = {
//       id,
//       handleDelete,
//       type: 'DELETE'
//     }
//     openModal(modalData)
//   }

//   const [centres, setCentres] = useState([])

//   useEffect(() => {
//     // Fetch the centres names from the API
//     const fetchCentres = async () => {
//       try {
//         // const response = await axios.get('http://localhost:5000/schools/names0')
//         const response = await axios.get('https://api-codina-af1a88eea621.herokuapp.com/schools/names0')
//         setCentres(response.data)
//       } catch (error) {
//         console.error('Error fetching centres', error)
//       }
//     }

//     fetchCentres()
//   }, [])

//   return (
//     <>
//       <Formik
//         enableReinitialize={true}
//         initialValues={{ ...fields }}
//         onSubmit={(values) => {
//           handleSubmit(values)
//         }}
//       >
//         {({
//           values,
//           errors,
//           touched,
//           handleBlur,
//           setFieldValue,
//           submitForm
//         }) => (
//           <Form className="flex flex-col w-full space-y-4">
//             <div className="h-32 grid grid-cols-12 grid-rows-6 gap-6 p-4 m-0 border border-gray-300 shadow rounded-sm-sm print:p-0 bg-gray-50 print:bg-white print:border-0 print:shadow-none">
//               {/* NOM */}
//               <div title="NOM" className="col-span-4 row-span-1">
//                 <div className="flex flex-row items-center mb-3">
//                   <label
//                     className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
//                     htmlFor="NOM"
//                   >
//                     Nom
//                   </label>

//                   <ErrorMessage
//                     name="NOM"
//                     render={(message) => (
//                       <span className="text-sm font-bold leading-4 text-red-600">
//                         {message}
//                       </span>
//                     )}
//                   />
//                 </div>

//                 <Field
//                   className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
//                     touched.NOM && errors.NOM
//                       ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
//                       : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
//                   }`}
//                   name="NOM"
//                   type="text"
//                   placeholder="Escriure..."
//                 />
//               </div>

//               {/* COGNOM */}
//               <div title="COGNOM" className="col-span-4 row-span-1">
//                 <div className="flex flex-row items-center mb-3">
//                   <label
//                     className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
//                     htmlFor="COGNOM"
//                   >
//                     Cognom
//                   </label>

//                   <ErrorMessage
//                     name="COGNOM"
//                     render={(message) => (
//                       <span className="text-sm font-bold leading-4 text-red-600">
//                         {message}
//                       </span>
//                     )}
//                   />
//                 </div>

//                 <Field
//                   className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
//                     touched.COGNOM && errors.COGNOM
//                       ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
//                       : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
//                   }`}
//                   name="COGNOM"
//                   type="text"
//                   placeholder="Escriure..."
//                 />
//               </div>

//               {/* ID_ESCOLA */}
//               <div title="ID_ESCOLA" className="col-span-4 row-span-1">
//                 <div className="flex flex-row items-center mb-3">
//                   <label
//                     className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
//                     htmlFor="ID_ESCOLA"
//                   >
//                     Escola
//                   </label>

//                   <ErrorMessage
//                     name="ID_ESCOLA"
//                     render={(message) => (
//                       <span className="text-sm font-bold leading-4 text-red-600">
//                         {message}
//                       </span>
//                     )}
//                   />
//                 </div>

//                 <Field
//                   as="select"
//                   className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
//                     touched.ID_ESCOLA && errors.ID_ESCOLA
//                       ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
//                       : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
//                   }`}
//                   name="ID_ESCOLA"
//                   onChange={(e) => setFieldValue("ID_ESCOLA", parseInt(e.target.value))}
//                 >
//                   <option value="">Seleccioneu una escola...</option>
//                   {centres.map((centre) => (
//                     <option key={centre.ID_ESCOLA} value={centre.ID_ESCOLA}>
//                       {centre.NOM}
//                     </option>
//                   ))}
//                 </Field>
//               </div>
//             </div>

//             <div className="grid w-full grid-cols-12 pt-4 m-0 gap-y-4">
//               <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
//                 <button
//                   alt="GUARDAR"
//                   title="GUARDAR"
//                   className="flex flex-row items-center justify-center w-full col-span-1 p-2 text-white transition duration-300 bg-green-600 border border-green-700 rounded-sm-sm hover:border-green-300 hover:bg-indigo-200 hover:text-green-700 font-ms-semi"
//                   type="submit"
//                 >
//                   <AiOutlineSave size={25} className="mr-2" />
//                   <p className="text-base font-semibold">Guardar</p>
//                 </button>
//               </div>

//               <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
//                 {!isNew && (
//                   <button
//                     alt="ELIMINAR"
//                     title="ELIMINAR"
//                     className="flex flex-row items-center justify-center w-full p-2 text-white transition duration-300 bg-red-600 border border-red-700 rounded-sm-sm hover:border-red-300 hover:bg-red-200 hover:text-red-700 font-ms-semi"
//                     onClick={() => {
//                       handleDeleteModal(values.ID_PROFESSOR)
//                     }}
//                     type="button"
//                   >
//                     <AiOutlineDelete size={25} className="mr-2" />
//                     <p className="text-base font-semibold">Eliminar</p>
//                   </button>
//                 )}
//               </div>
//             </div>
//           </Form>
//         )}
//       </Formik>
//       <div className="pt-4 w-full h-full bg-white">
//         <div className="w-full mb-3 p-4 print:py-0 print:m-0 bg-gray-200 border-2 border-indigo-800 rounded print:border-0">
//           <AdminFormDetail 
//             handleSubmit={handleSubmit} 
//             handleDelete={handleDelete} 
//             fields={fields} 
//             isNew={isNew} 
//           />
//         </div>
//       </div>
//     </>
//   )
// }

// TeachersFormDetail.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
//   handleDelete: PropTypes.func.isRequired,
//   fields: PropTypes.object,
//   isNew: PropTypes.bool
// }

// export default TeachersFormDetail
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ModalContext } from 'contexts/ModalContext';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineSave, AiOutlineDelete } from 'react-icons/ai';
import axios from 'axios';

const TeachersFormDetail = ({ fields, handleSubmit, handleDelete, isNew }) => {
  const { openModal } = useContext(ModalContext);
  const handleDeleteModal = (id) => {
    const modalData = {
      id,
      handleDelete,
      type: 'DELETE'
    };
    openModal(modalData);
  };

  const [centres, setCentres] = useState([]);

  useEffect(() => {
    // Fetch the centres names from the API
    const fetchCentres = async () => {
      try {
        // const response = await axios.get('http://localhost:5000/schools/names0');
        const response = await axios.get('https://api-codina-af1a88eea621.herokuapp.com/schools/names0');
        setCentres(response.data);
      } catch (error) {
        console.error('Error fetching centres', error);
      }
    };

    fetchCentres();
  }, []);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          submitForm
        }) => (
          <Form className="flex flex-col w-full space-y-4">
            <div className="grid grid-cols-12 grid-rows-6 gap-6 p-4 m-0 border border-gray-300 shadow rounded-sm-sm print:p-0 bg-gray-50 print:bg-white print:border-0 print:shadow-none">
              {/* NOM */}
              <div title="NOM" className="col-span-4 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="NOM"
                  >
                    Nom
                  </label>

                  <ErrorMessage
                    name="NOM"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.NOM && errors.NOM
                      ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                      : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="NOM"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>

              {/* COGNOM */}
              <div title="COGNOM" className="col-span-4 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="COGNOM"
                  >
                    Cognom
                  </label>

                  <ErrorMessage
                    name="COGNOM"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.COGNOM && errors.COGNOM
                      ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                      : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="COGNOM"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>

              {/* ID_ESCOLA */}
              <div title="ID_ESCOLA" className="col-span-4 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="ID_ESCOLA"
                  >
                    Escola
                  </label>

                  <ErrorMessage
                    name="ID_ESCOLA"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  as="select"
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.ID_ESCOLA && errors.ID_ESCOLA
                      ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                      : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="ID_ESCOLA"
                  onChange={(e) => setFieldValue("ID_ESCOLA", parseInt(e.target.value))}
                >
                  <option value="">Seleccioneu una escola...</option>
                  {centres.map((centre) => (
                    <option key={centre.ID_ESCOLA} value={centre.ID_ESCOLA}>
                      {centre.NOM}
                    </option>
                  ))}
                </Field>
              </div>

              {/* USERNAME */}
              <div title="UserName" className="col-span-4 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="UserName"
                  >
                    Nom Usuari
                  </label>

                  <ErrorMessage
                    name="UserName"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.UserName && errors.UserName
                      ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                      : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="UserName"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>

              {/* PASSWORD */}
              <div title="Password" className="col-span-4 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="Password"
                  >
                    Contrasenya
                  </label>

                  <ErrorMessage
                    name="Password"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.Password && errors.Password
                      ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                      : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="Password"
                  type="password"
                  placeholder="Escriure..."
                />
              </div>

              {/* EMAIL */}
              <div title="Email" className="col-span-4 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="Email"
                  >
                    Email
                  </label>

                  <ErrorMessage
                    name="Email"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.Email && errors.Email
                      ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                      : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="Email"
                  type="email"
                  placeholder="Escriure..."
                />
              </div>
            </div>

            <div className="grid w-full grid-cols-12 pt-4 m-0 gap-y-4">
              <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
                <button
                  alt="GUARDAR"
                  title="GUARDAR"
                  className="flex flex-row items-center justify-center w-full col-span-1 p-2 text-white transition duration-300 bg-green-600 border border-green-700 rounded-sm-sm hover:border-green-300 hover:bg-indigo-200 hover:text-green-700 font-ms-semi"
                  type="submit"
                >
                  <AiOutlineSave size={25} className="mr-2" />
                  <p className="text-base font-semibold">Guardar</p>
                </button>
              </div>

              <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
                {!isNew && (
                  <button
                    alt="ELIMINAR"
                    title="ELIMINAR"
                    className="flex flex-row items-center justify-center w-full p-2 text-white transition duration-300 bg-red-600 border border-red-700 rounded-sm-sm hover:border-red-300 hover:bg-red-200 hover:text-red-700 font-ms-semi"
                    onClick={() => {
                      handleDeleteModal(values.ID_PROFESSOR);
                    }}
                    type="button"
                  >
                    <AiOutlineDelete size={25} className="mr-2" />
                    <p className="text-base font-semibold">Eliminar</p>
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

TeachersFormDetail.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isNew: PropTypes.bool
};

export default TeachersFormDetail;
