// PropTypes + REACT HOOKS
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import useFetch from 'hooks/useFetch'

// COMPONENTS
import MailSplitCompGrid from 'components/Mails/MailSplit/MailSplitCompGrid'
import { toast } from 'react-toastify'

// CONTEXTS
import { useLocation } from 'wouter'
import { TitleContext } from 'contexts/TitleContext'
import { ModalContext } from 'contexts/ModalContext'

// ASSETS
import loadingIco from 'components/ui/Logo/loading_azul.gif'
import { ImArrowLeft2 } from 'react-icons/im'
import { UserContext } from 'contexts/UserContext'

export default function MailSplitViewGrid({ params }) {
  // Contextos:
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)
  const { openModal } = useContext(ModalContext)
  const { user } = useContext(UserContext)

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()
  const [getApiCallDelete, setApiCallDelete] = useFetch()

  // Estados:
  const [getIsMultiMail, setIsMultiMail] = useState(false)

  // ############################################################

  // CRUD: GET
  useEffect(() => {
    if (!getApiCallPost.error && !getApiCallDelete.error) {
      // Llamamos a la API para recoger el registro que se quiere editar:
      const apiCallGet = {
        url: `mails/${params.idMail}/mail-split`,
        method: 'GET',
        messageKo: '¡Error cargando PDFs Factura!'
      }
      setApiCallGet(apiCallGet)
    }
  }, [getApiCallPost.data, getApiCallDelete.data])

  // * Recoger datos API: GET
  useEffect(() => {
    // Título de la página:
    const title = {
      name: `Facturas - Clientes`,
      buttons: [
        {
          id: 'btnBack',
          name: <ImArrowLeft2 size={25} />,
          link: `/mails/grid`
        }
      ],
      btnSendAll: true,
      btnSendAllText: 'Enviar Todos',
      handleSendModal,
      button: 'print',
      export: true,
      csvData:
        getApiCallGet.data !== null &&
        getApiCallGet.data !== undefined &&
        getApiCallGet.data[0] !== null
          ? getApiCallGet.data
          : null
    }
    setTitle(title)
  }, [getApiCallGet.data])

  // ############################################################

  // CRUD: POST (¡Enviamiento individual!)
  const handleSendMail = async (values) => {
    setIsMultiMail(false)

    const apiCallPost = {
      url: `mails/send`,
      method: 'POST',
      body: values
    }
    await setApiCallPost(apiCallPost)
  }

  // CRUD: POST (¡Enviamiento masivo!)
  const handleSendAllMails = async (values) => {
    setIsMultiMail(true)

    // Llamamos a la API para enviar todos los correos:
    const apiCallPost = {
      url: `mails/send-all`,
      method: 'POST',
      body: values
    }
    await setApiCallPost(apiCallPost)
  }

  // * Mostrar mensaje API: POST / PUT
  // ! Mostrar error API: POST / PUT
  useEffect(() => {
    if (getApiCallPost.isCalled) {
      if (getIsMultiMail) {
        handleLoadingModal(
          getApiCallPost.loading,
          `/mails/grid`,
          'Enviando correos... ¡En unos segundos estarán enviados!',
          getApiCallPost.error,
          getApiCallPost.message,
          false
        )
      }

      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallPost.message) {
        successMessage(getApiCallPost.message)
      }

      if (getApiCallPost.error) {
        errorMessage(getApiCallPost.error)
      }
    }
  }, [getApiCallPost.loading])

  // Modal enviar mail/s:
  const handleSendModal = () => {
    const modalData = {
      values: {
        Mails_Id: getApiCallGet?.data?.mail.id,
        Mails_DateMime: getApiCallGet?.data?.mail.DateMail,
        Mails_Subject: getApiCallGet?.data?.mail.Subject,
        Mails_Message: getApiCallGet?.data?.mail.Message,
        Mails_FileName: getApiCallGet?.data?.mail.FileName,
        Mails_FilePath: getApiCallGet?.data?.mail.FilePath,
        Mails_Comment: null,
        UserMail: user.email
      },
      handleSend: handleSendAllMails,
      isAll: true,
      type: 'SEND'
    }

    openModal(modalData)
  }

  // ############################################################

  // CRUD: DELETE
  const handleDelete = async (values) => {
    const apiCallDelete = {
      url: `mails/${values.idMail}/mail-split/${values.idMailSplit}`,
      method: 'DELETE',
      body: values
    }
    await setApiCallDelete(apiCallDelete)
  }

  // * Mostrar mensaje API: DELETE
  // ! Mostrar error API: DELETE
  useEffect(() => {
    if (getApiCallDelete.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallDelete.message) {
        successMessage(getApiCallDelete.message)
        setLocation(`/mails/${params.idMail}/mail-split`)
      }

      if (getApiCallDelete.error) {
        errorMessage(getApiCallDelete.error)
        setLocation(`/mails/${params.idMail}/mail-split`)
      }
    }
  }, [getApiCallDelete.loading])

  // ############################################################

  // Modal cargando cambios:
  const handleLoadingModal = (
    isLoading,
    url,
    message,
    apiCallError,
    apiCallMessage,
    isSync
  ) => {
    const modalData = {
      isLoading,
      url,
      message,
      apiCallError,
      apiCallMessage,
      isSync,
      type: 'LOADING'
    }

    openModal(modalData)
  }

  // ############################################################

  // 'handleClick()' para cada item en una lista, al pulsar un item nos llevará a la ruta especificada en el 'setLocation':
  const handleClick = (e, item) => {
    e.preventDefault()

    if (
      (item.MailSplit_NameSingle === null ||
        item.MailSplit_NameSingle === '') &&
      (item.MailSplit_EmailSingle === null || item.MailSplit_EmailSingle === '')
    ) {
      setLocation(
        `/singles/${item.MailSplit_IdSingle}/${item.MailSplit_IdMail}/register`
      )
    } else {
      setLocation(
        `/singles/${item.MailSplit_IdSingle}/${item.MailSplit_IdMail}`
      )
    }
  }

  return getApiCallGet?.data && !getApiCallGet?.loading ? (
    <div className="md:flex">
      <div
        className={`w-full ${
          getApiCallGet.data.mailSplits.length > 0 ? 'block' : 'hidden'
        }`}
      >
        <div className="flex flex-col">
          <MailSplitCompGrid
            items={getApiCallGet.data.mailSplits}
            handleClick={handleClick}
            handleSendMail={handleSendMail}
            handleDelete={handleDelete}
          />
        </div>
      </div>

      <div
        className={`w-full ${
          getApiCallGet.data.mailSplits.length > 0 ? 'hidden' : 'block'
        }`}
      >
        <div className="flex flex-col">
          <div
            className="bg-red-100 border-t-4 border-red-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <svg
                  className="fill-current h-6 w-6 text-red-500 mr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              <div>
                <p className="font-bold">El buzón está vacío</p>
                <p className="text-sm">No se han enviado correos.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="h-full w-full flex justify-center items-center content-center">
        <p
          className="h-full text-blue-700 font-extrabold flex justify-center flex-col-reverse items-center"
          title="Cargando..."
        >
          ¡Cargando!
          <div className="mt-16 mb-8" style={{ width: '75px', height: '75px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
              <style>
                {`
                  @keyframes zoomInOut {
                    0%, 100%, 5%, 95% {
                      transform: translate(50px, 50px) scale(0);
                    }
                    20%, 80% {
                      transform: translate(10px, 10px) scale(.8);
                    }
                  }
                  
                  @keyframes spin {
                    from {
                      transform: rotate(0);
                    }
                    to {
                      transform: rotate(360deg);
                    }
                  }
                `}
              </style>

              <defs>
                <mask id="Mask">
                  <circle cx="50" cy="50" r="50" fill="#fff"></circle>
                  <circle
                    cx="50"
                    cy="50"
                    r="50"
                    style={{
                      animationName: 'zoomInOut',
                      animationDuration: '5.2s',
                      animationIterationCount: 'infinite',
                      animationTimingFunction: 'linear'
                    }}
                  ></circle>
                </mask>
              </defs>

              <g
                style={{
                  animationName: 'spin',
                  animationDuration: '1.3s',
                  transformOrigin: 'center',
                  animationIterationCount: 'infinite',
                  animationTimingFunction: 'linear'
                }}
                fill="#1C69D4"
                mask="url(#Mask)"
              >
                <path d="M0 0h50v50H0zM50 50h50v50H50z"></path>
              </g>
            </svg>
          </div>
        </p>
      </div>
    </div>
  )
}

MailSplitViewGrid.propTypes = {
  params: PropTypes.any
}
