// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

// COMPONENTS
import SchoolsTable from 'components/ui/Table/SchoolsTable'
import ColumnFilter from 'components/ui/Table/ColumnFilter'
import ColumnFilterSelect from 'components/ui/Table/ColumnFilterSelect'

export default function SchoolsCompGrid({ items, handleClick }) {
  const [getTableData, setTableData] = useState([])

  const columns = [
    {
      Header: 'Nom',
      accessor: 'NOM',
      Filter: ColumnFilter,
      Type: 'name',
      Key: 'name'
    },
    {
      Header: 'Codi',
      accessor: 'CODI',
      Filter: ColumnFilter,
      Type: 'code',
      Key: 'code'
    },
    {
      Header: 'Centre',
      accessor: 'CENTRE',
      Filter: ColumnFilter,
      Type: 'center',
      Key: 'center'
    },
    {
      Header: 'CCC',
      accessor: 'CCC',
      Filter: ColumnFilter,
      Type: 'ccc',
      Key: 'ccc'
    },
    {
      Header: 'Codi Inicialització Becari',
      accessor: 'CODI_BECARI',
      Filter: ColumnFilter,
      Type: 'codi_becari',
      Key: 'codi_becari'
    }
  ]

  useEffect(() => {
    if (items) {
      const itemsData = items.map((item) => ({
        ID_ESCOLA: item.ID_ESCOLA,
        NOM: item.NOM,
        CODI: item.CODI,
        CENTRE: item.CENTRE,
        CCC: item.CCC,
        CODI_BECARI: item.CODI_BECARI,
      }))
      setTableData(itemsData)
    }
  }, [items])

  return (
    <div className="overflow-x-auto shadow-md print:shadow-none">
      {getTableData.length > 0 && (
        <SchoolsTable
          data={getTableData}
          columns={columns}
          handleClick={handleClick}
        />
      )}
    </div>
  )
}

SchoolsCompGrid.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  value: PropTypes.any
}
