// // PropTypes & HOOKS
// import React from 'react'
// import PropTypes from 'prop-types'
// import { useLocation, Link } from 'wouter'

// // DATA
// import { menuItems } from 'data/menuItems'

// // ICONS
// import dashboardIcon from 'assets/icons/iconDashboard.svg'
// import singleIcon from 'assets/icons/iconSingle.svg'
// import adminIcon from 'assets/icons/gear_white_transparent.png'
// import homeIcon from 'assets/icons/home.svg'
// import iconKey from 'assets/icons/iconKey_white_transparent.png'
// import iconMail from 'assets/icons/mail.png'
// import iconLogout from 'assets/icons/logout_white_transparent.png'
// import iconCouple from 'assets/icons/iconCouple.svg'

// export default function Menu({ device, handleMobileMenu }) {
//   const [location] = useLocation()

//   if (device === 'mobile') {
//     return (
//       <>
//         {menuItems.map((item) => (
//           <Link
//             key={item.slug}
//             href={item.slug}
//             onClick={() => handleMobileMenu()}
//           >
//             <a
//               className={`${
//                 item.slug === location
//                   ? 'bg-secondary text-white'
//                   : 'text-white hover:bg-secondary hover:text-white'
//               } block px-3 py-3 rounded-md text-base font-medium`}
//             >
//               {item.title}
//             </a>
//           </Link>
//         ))}
//       </>
//     )
//   } else {
//     return (
//       <>
//         {menuItems.map((item) => (
//           <Link href={item.slug} key={item.slug}>
//             <a
//               className={`flex px-4 py-2 mt-2 text-sm font-semibold rounded
//                   ${
//                     item.slug === location
//                       ? 'bg-[#66618A] text border-blue-700 text-white font-bold'
//                       : 'bg-transparent text-white'
//                   }

//                   ${
//                     item.active
//                       ? 'hover:text-white focus:text-blue-900 hover:bg-[#66618A] hover:font-bold focus:bg-gray-200 hover:border hover:border-white'
//                       : 'hover:cursor-default'
//                   }
                       
//                   ${item.subMenu ? 'ml-6' : 'ml-0'}

//                   ${item.title === 'Administrar' && 'cursor-default'} 
//               `}
//             >
//               <img
//                 src={
//                   item.title === 'Panell'
//                     ? dashboardIcon
//                     : item.title === 'Clientes'
//                     ? singleIcon
//                     : item.title === 'ALUMNES'
//                     ? iconCouple
//                     : item.title === 'PROFESSORS'
//                     ? singleIcon
//                     : item.title === 'Codina'
//                     ? adminIcon
//                     : item.title === 'Usuaris'
//                     ? iconKey
//                     : item.title === 'Cerrar'
//                     ? iconLogout
//                     : homeIcon
//                 }
//                 alt="customers"
//                 className="w-5 mr-2 h-5"
//               />

//               <span>{item.title.toUpperCase()}</span>
//             </a>
//           </Link>
//         ))}
//       </>
//     )
//   }
// }

// Menu.propTypes = {
//   device: PropTypes.string,
//   handleMobileMenu: PropTypes.func
// }


// PropTypes & HOOKS
// PropTypes & HOOKS
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useLocation, Link } from 'wouter'

// DATA
import { menuItems } from 'data/menuItems'

// ICONS
import dashboardIcon from 'assets/icons/iconDashboard.svg'
import singleIcon from 'assets/icons/iconSingle.svg'
import adminIcon from 'assets/icons/gear_white_transparent.png'
import homeIcon from 'assets/icons/home.svg'
import iconKey from 'assets/icons/iconKey_white_transparent.png'
import iconMail from 'assets/icons/mail.png'
import iconLogout from 'assets/icons/logout_white_transparent.png'
import iconCouple from 'assets/icons/iconCouple.svg'

// CONTEXT
import { UserContext } from 'contexts/UserContext'

export default function Menu({ device, handleMobileMenu }) {
  const [location] = useLocation()
  const { user } = useContext(UserContext)

  const filteredMenuItems = menuItems.filter(
    (item) => !item.roles || item.roles.includes(user?.role_id)
  )

  if (device === 'mobile') {
    return (
      <>
        {filteredMenuItems.map((item) => (
          <Link
            key={item.slug}
            href={item.slug}
            onClick={() => handleMobileMenu()}
          >
            <a
              className={`${
                item.slug === location
                  ? 'bg-secondary text-white'
                  : 'text-white hover:bg-secondary hover:text-white'
              } block px-3 py-3 rounded-md text-base font-medium`}
            >
              {item.title}
            </a>
          </Link>
        ))}
      </>
    )
  } else {
    return (
      <>
        {filteredMenuItems.map((item) => (
          <Link href={item.slug} key={item.slug}>
            <a
              className={`flex px-4 py-2 mt-2 text-sm font-semibold rounded
                  ${
                    item.slug === location
                      ? 'bg-[#66618A] text border-blue-700 text-white font-bold'
                      : 'bg-transparent text-white'
                  }
                  ${
                    item.active
                      ? 'hover:text-white focus:text-blue-900 hover:bg-[#66618A] hover:font-bold focus:bg-gray-200 hover:border hover:border-white'
                      : 'hover:cursor-default'
                  }
                  ${item.subMenu ? 'ml-6' : 'ml-0'}
                  ${item.title === 'Administrar' && 'cursor-default'}
              `}
            >
              <img
                src={
                  item.title === 'Panell'
                    ? dashboardIcon
                    : item.title === 'Clientes'
                    ? singleIcon
                    : item.title === 'ALUMNES'
                    ? iconCouple
                    : item.title === 'ADMINS'
                    ? singleIcon
                    : item.title === 'Codina'
                    ? adminIcon
                    : item.title === 'Usuaris'
                    ? iconKey
                    : item.title === 'Cerrar'
                    ? iconLogout
                    : homeIcon
                }
                alt="icon"
                className="w-5 mr-2 h-5"
              />
              <span>{item.title.toUpperCase()}</span>
            </a>
          </Link>
        ))}
      </>
    )
  }
}

Menu.propTypes = {
  device: PropTypes.string,
  handleMobileMenu: PropTypes.func
}
