// PropTypes & React HOOKS
import PropTypes from 'prop-types'
import { useContext } from 'react'

// React Table HOOK
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useGlobalFilter
} from 'react-table'

// COMPONENTS
import MailSplitTableBar from './MailSplitTableBar'
import { ModalContext } from 'contexts/ModalContext'

// ICONS
import FilterUp from 'components/icons/FilterUp'
import FilterDown from 'components/icons/FilterDown'
import {
  BsFillFileEarmarkPdfFill,
  BsFillSendFill,
  BsSendCheckFill,
  BsSendXFill,
  BsTrash
} from 'react-icons/bs'

export default function MailSplitTable({
  data,
  columns,
  handleSendMail,
  handleDelete,
  handleClick
}) {
  // Inicializar la tabla:
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state,
    prepareRow
  } = useTable(
    { columns, data, initialState: { pageSize: 150 } },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize } = state
  const { openModal } = useContext(ModalContext)

  const handleSendModal = (values) => {
    const modalData = {
      values,
      handleSend: handleSendMail,
      isAll: false,
      type: 'SEND'
    }

    openModal(modalData)
  }

  const handleDeleteModal = (idMailSplit, idMail, fileName) => {
    const modalData = {
      id: {
        idMail,
        idMailSplit,
        mailSplitFileName: fileName
      },
      handleDelete,
      type: 'DELETE'
    }

    openModal(modalData)
  }

  return (
    <div className="flex flex-col">
      <div className="align-middle inline-block min-w-full">
        {/* Tabla: */}
        <table
          className="table-auto min-w-full border-2 border-blue-600"
          {...getTableProps()}
        >
          {/* Cabecera: */}
          <thead className="bg-blue-700">
            {headerGroups.map((headerGroup) => (
              <tr
                className="divide-x divide-dashed divide-blue-600"
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => {
                  return column.render('Header') !== 'Archivo' &&
                    column.render('Header') !== 'Enviar' &&
                    column.render('Header') !== 'Eliminar' ? (
                    <th
                      className="text-white py-2 hover:bg-blue-500 font-ms-semi"
                      key={column.id}
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({ title: undefined })
                      )}
                    >
                      <div
                        className={`text-left text-sm 
                        ${
                          column.render('Header') === 'Enviado'
                            ? 'flex justify-center items-center'
                            : 'ml-4'
                        }`}
                      >
                        {column.render('Header')}

                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FilterDown
                              width={10}
                              height={10}
                              className="inline-block ml-1 -mt-1"
                              fill="black"
                            />
                          ) : (
                            <FilterUp
                              width={10}
                              height={10}
                              className="inline-block ml-1 -mt-1"
                              fill="black"
                            />
                          )
                        ) : (
                          ''
                        )}
                      </div>

                      <div className="mx-4">
                        {column.canFilter && column.render('Filter')}
                      </div>
                    </th>
                  ) : (
                    <th
                      className="text-white py-2 font-ms-semi"
                      key={column.id}
                    >
                      <div className="flex justify-center items-center text-left text-sm">
                        {column.render('Header')}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          {/* Cuerpo: */}
          <tbody
            style={{ borderColor: '#9CA3AF' }}
            className="bg-white divide-y divide-gray-200"
            {...getTableBodyProps()}
          >
            {page.map((row, index) => {
              prepareRow(row)

              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  className={`hover:bg-blue-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-blue-200
                    ${index % 2 === 0 && 'bg-blue-50'}`}
                >
                  <td
                    className={`text-sm font-bold py-4 w-1/12`}
                    onClick={(e) => handleClick && handleClick(e, row.original)}
                  >
                    <div className="flex justify-center items-center">
                      <p
                        className="w-1/2 m-auto transition duration-300 
                        border-gray-600 bg-gray-500 text-white py-2 px-2 border-2 rounded 
                        flex justify-center items-center"
                        title="Número identificador de cliente"
                      >
                        {page[index].original.MailSplit_IdSingle}
                      </p>
                    </div>
                    {/* <span className='w-full flex justify-center items-center text-bold italic'></span> */}
                  </td>

                  <td
                    className={`text-sm text-bold pl-4 py-4 w-3/12`}
                    onClick={(e) => handleClick && handleClick(e, row.original)}
                  >
                    {!page[index].original.MailSplit_NameSingle ? (
                      !page[index].original.MailSplit_EmailSingle ? (
                        <div className="flex justify-center items-center">
                          <p
                            className="w-3/4 m-auto transition duration-300 
                            border-red-600 bg-red-500 text-white font-sm py-1 px-2 border-2 rounded font-bold
                            flex justify-center items-center animate-pulse"
                            title="Cliente desconocido"
                          >
                            CLIENTE NO REGISTRADO
                          </p>
                        </div>
                      ) : (
                        <div className="flex justify-center items-center">
                          <p
                            className="w-3/4 m-auto transition duration-300 
                            border-amber-600 bg-amber-500 text-white font-sm py-1 px-2 border-2 rounded font-bold
                            flex justify-center items-center"
                            title="Cliente sin correo"
                          >
                            SIN NOMBRE
                          </p>
                        </div>
                      )
                    ) : (
                      <span className="font-bold">
                        {page[index].original.MailSplit_NameSingle}
                      </span>
                    )}
                  </td>

                  <td
                    className={`text-sm text-bold pl-4 py-4 w-3/12`}
                    onClick={(e) => handleClick && handleClick(e, row.original)}
                  >
                    {!page[index].original.MailSplit_EmailSingle ? (
                      !page[index].original.MailSplit_NameSingle ? (
                        <div className="flex justify-center items-center">
                          <p
                            className="w-3/4 m-auto transition duration-300 
                            border-red-600 bg-red-500 text-white font-sm py-1 px-2 border-2 rounded font-bold
                            flex justify-center items-center animate-pulse"
                            title="Cliente desconocido"
                          >
                            CLIENTE NO REGISTRADO
                          </p>
                        </div>
                      ) : (
                        <div className="flex justify-center items-center">
                          <p
                            className="w-3/4 m-auto transition duration-300 
                            border-amber-600 bg-amber-500 text-white font-sm py-1 px-2 border-2 rounded font-bold
                            flex justify-center items-center"
                            title="Cliente sin correo"
                          >
                            SIN CORREO
                          </p>
                        </div>
                      )
                    ) : (
                      <span className="font-bold">
                        {page[index].original.MailSplit_EmailSingle}
                      </span>
                    )}
                  </td>

                  <td
                    className={`text-sm py-4 w-1/12`}
                    onClick={(e) => handleClick && handleClick(e, row.original)}
                  >
                    {page[index].original.MailSplit_IsSent ? (
                      <div className="flex justify-center items-center">
                        <p
                          className="flex justify-center items-center text-lg text-green-800 font-bold w-10 h-10 
                          border-2 border-green-400 bg-green-300 rounded-full cursor-default"
                        >
                          <BsSendCheckFill
                            size={25}
                            title="Archivo enviado al cliente"
                          />
                        </p>
                      </div>
                    ) : (
                      <div className="flex justify-center items-center">
                        <p
                          className="flex justify-center items-center text-lg text-red-800 font-bold w-10 h-10
                          border-2 border-red-400 bg-red-300 rounded-full cursor-default"
                        >
                          <BsSendXFill
                            size={25}
                            title="Archivo no enviado al cliente"
                          />
                        </p>
                      </div>
                    )}
                  </td>

                  <td className={`text-sm py-4 w-1/12`}>
                    <a
                      className="w-1/2 m-auto transition duration-300 
                        border-cyan-600 bg-cyan-500 text-white hover:border-cyan-400 hover:bg-cyan-300 hover:text-cyan-600
                        font-ms-semi py-1 px-2 border-2 rounded 
                        flex justify-center items-center"
                      href={page[index].original.MailSplit_FilePath}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <BsFillFileEarmarkPdfFill size={30} title="Visualizar" />
                    </a>
                  </td>

                  <td className={`text-sm py-4 w-1/12`}>
                    <button
                      className={`w-1/2 m-auto transition duration-300 
                        font-ms-semi py-1 px-2 border-2 rounded 
                        flex justify-center items-center
                        ${
                          page[index].original.MailSplit_EmailSingle !== null &&
                          page[index].original.MailSplit_EmailSingle !== ''
                            ? 'border-teal-600 bg-teal-500 text-white hover:border-teal-400 hover:bg-teal-300 hover:text-teal-600'
                            : 'border-gray-300 bg-gray-200 text-white cursor-not-allowed'
                        }`}
                      type="button"
                      onClick={() => {
                        handleSendModal(page[index].original)
                      }}
                      disabled={
                        page[index].original.MailSplit_EmailSingle === null ||
                        page[index].original.MailSplit_EmailSingle === ''
                      }
                    >
                      <BsFillSendFill size={30} title="Enviar" />
                    </button>
                  </td>

                  <td className={`text-sm py-4 w-1/12`}>
                    <button
                      className="w-1/2 m-auto transition duration-300 
                        font-ms-semi py-1 px-2 border-2 rounded 
                        flex justify-center items-center border-red-600 bg-red-500 
                        text-white hover:border-red-400 hover:bg-red-300 hover:text-red-600"
                      type="button"
                      onClick={() => {
                        handleDeleteModal(
                          page[index].original.MailSplit_Id,
                          page[index].original.MailSplit_IdMail,
                          page[index].original.MailSplit_FileName
                        )
                      }}
                    >
                      <BsTrash size={30} title="Eliminar" />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>

          {/* Pie: */}
          <tfoot>
            <tr>
              <td colSpan={columns.length} className="p-0 m-0">
                <MailSplitTableBar
                  previousPage={previousPage}
                  canPreviousPage={canPreviousPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  nextPage={nextPage}
                  canNextPage={canNextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  datos={data}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

MailSplitTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  handleSendMail: PropTypes.func,
  handleDelete: PropTypes.func
}
