// import PropTypes from 'prop-types'
// import { Formik, Form, Field, ErrorMessage } from 'formik'
// import * as Yup from 'yup'
// import { ModalContext } from 'contexts/ModalContext'
// import { useContext, useEffect, useState } from 'react'
// import { AiOutlineSave, AiOutlineDelete } from 'react-icons/ai'
// import axios from 'axios'

// const SchoolsFormDetail = ({ fields, handleSubmit, handleDelete, isNew }) => {
//   const { openModal } = useContext(ModalContext)
//   const handleDeleteModal = (id) => {
//     const modalData = {
//       id,
//       handleDelete,
//       type: 'DELETE'
//     }
//     openModal(modalData)
//   }

//   const [centres, setCentres] = useState([])

//   useEffect(() => {
//     // Fetch the centres names from the API
//     const fetchCentres = async () => {
//       try {
//         // const response = await axios.get('http://localhost:5000/schools/names0')
//         const response = await axios.get('https://api-codina-af1a88eea621.herokuapp.com/schools/names0')
//         setCentres(response.data)
//       } catch (error) {
//         console.error('Error fetching centres', error)
//       }
//     }

//     fetchCentres()
//   }, [])

//   return (
//     <>
//       <Formik
//         enableReinitialize={true}
//         initialValues={{ ...fields }}
//         onSubmit={(values) => {
//           // Modify the value before submitting
//           const selectedCentre = centres.find(centre => centre.ID_ESCOLA === values.CENTRE)
//           const modifiedValues = {
//             ...values,
//             CENTRE: selectedCentre ? selectedCentre.NOM : values.CENTRE
//           }
//           handleSubmit(modifiedValues)
//         }}
//       >
//         {({
//           values,
//           errors,
//           touched,
//           handleBlur,
//           setFieldValue,
//           submitForm
//         }) => (
//           <Form className="flex flex-col w-full space-y-4">
//             <div className="grid grid-cols-12 grid-rows-6 gap-6 p-4 m-0 border border-gray-300 shadow rounded-sm-sm print:p-0 bg-gray-50 print:bg-white print:border-0 print:shadow-none">
//               <div title="NOM" className="col-span-6 row-span-1">
//                 <div className="flex flex-row items-center mb-3">
//                   <label
//                     className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
//                     htmlFor="NOM"
//                   >
//                     Nom
//                   </label>
//                   <ErrorMessage
//                     name="NOM"
//                     render={(message) => (
//                       <span className="text-sm font-bold leading-4 text-red-600">
//                         {message}
//                       </span>
//                     )}
//                   />
//                 </div>
//                 <Field
//                   className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
//                     touched.NOM && errors.NOM
//                       ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
//                       : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
//                   }`}
//                   name="NOM"
//                   type="text"
//                   placeholder="Escriure..."
//                 />
//               </div>
//               <div title="CODI" className="col-span-3 row-span-1">
//                 <div className="flex flex-row items-center mb-3">
//                   <label
//                     className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
//                     htmlFor="CODI"
//                   >
//                     Codi
//                   </label>
//                   <ErrorMessage
//                     name="CODI"
//                     render={(message) => (
//                       <span className="text-sm font-bold leading-4 text-red-600">
//                         {message}
//                       </span>
//                     )}
//                   />
//                 </div>
//                 <Field
//                   className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
//                     touched.CODI && errors.CODI
//                       ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
//                       : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
//                   }`}
//                   name="CODI"
//                   type="text"
//                   placeholder="Escriure..."
//                 />
//               </div>
//               <div title="CENTRE" className="col-span-3 row-span-1">
//                  <div className="flex flex-row items-center mb-3">
//                    <label
//                     className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
//                     htmlFor="CENTRE"
//                   >
//                     Centre
//                   </label>

//                   <ErrorMessage
//                     name="CENTRE"
//                     render={(message) => (
//                       <span className="text-sm font-bold leading-4 text-red-600">
//                         {message}
//                       </span>
//                     )}
//                   />
//                 </div>

//                 <Field
//                   className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
//                 ${
//                   touched.CENTRE && errors.CENTRE
//                     ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
//                     : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
//                 }`}
//                   name="CENTRE"
//                   type="text"
//                   placeholder="Escriure..."
//                 />
//               </div>
//               <div title="CCC" className="col-span-3 row-span-1">
//                 <div className="flex flex-row items-center mb-3">
//                   <label
//                     className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
//                     htmlFor="CCC"
//                   >
//                     CCC
//                   </label>
//                   <ErrorMessage
//                     name="CCC"
//                     render={(message) => (
//                       <span className="text-sm font-bold leading-4 text-red-600">
//                         {message}
//                       </span>
//                     )}
//                   />
//                 </div>
//                 <Field
//                   className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
//                     touched.CCC && errors.CCC
//                       ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
//                       : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
//                   }`}
//                   name="CCC"
//                   type="text"
//                   placeholder="Escriure..."
//                 />
//               </div>
//             </div>
//             <div className="grid w-full grid-cols-12 pt-4 m-0 gap-y-4">
//               <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
//                 <button
//                   alt="GUARDAR"
//                   title="GUARDAR"
//                   className="flex flex-row items-center justify-center w-full col-span-1 p-2 text-white transition duration-300 bg-green-600 border border-green-700 rounded-sm-sm hover:border-green-300 hover:bg-indigo-200 hover:text-green-700 font-ms-semi"
//                   type="submit"
//                 >
//                   <AiOutlineSave size={25} className="mr-2" />
//                   <p className="text-base font-semibold">Guardar</p>
//                 </button>
//               </div>
//               <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
//                 {!isNew && (
//                   <button
//                     alt="ELIMINAR"
//                     title="ELIMINAR"
//                     className="flex flex-row items-center justify-center w-full p-2 text-white transition duration-300 bg-red-600 border border-red-700 rounded-sm-sm hover:border-red-300 hover:bg-red-200 hover:text-red-700 font-ms-semi"
//                     onClick={() => {
//                       handleDeleteModal(values.ID_ESCOLA)
//                     }}
//                     type="button"
//                   >
//                     <AiOutlineDelete size={25} className="mr-2" />
//                     <p className="text-base font-semibold">Eliminar</p>
//                   </button>
//                 )}
//               </div>
//             </div>
//           </Form>
//         )}
//       </Formik>
//     </>
//   )
// }

// SchoolsFormDetail.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
//   handleDelete: PropTypes.func.isRequired,
//   fields: PropTypes.object,
//   isNew: PropTypes.bool
// }

// export default SchoolsFormDetail


import PropTypes from 'prop-types'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { ModalContext } from 'contexts/ModalContext'
import { useContext, useEffect, useState } from 'react'
import { AiOutlineSave, AiOutlineDelete } from 'react-icons/ai'
import axios from 'axios'

const SchoolsFormDetail = ({ fields, handleSubmit, handleDelete, isNew }) => {
  const { openModal } = useContext(ModalContext)
  const handleDeleteModal = (id) => {
    const modalData = {
      id,
      handleDelete,
      type: 'DELETE',
      message: 'Si eliminas esta escuela, también se eliminarán el profesor asignado y los alumnos vinculados.'
    }
    openModal(modalData)
  }

  const [centres, setCentres] = useState([])

  useEffect(() => {
    // Fetch the centres names from the API
    const fetchCentres = async () => {
      try {
        // const response = await axios.get('http://localhost:5000/schools/names0')
        const response = await axios.get('https://api-codina-af1a88eea621.herokuapp.com/schools/names0')
        setCentres(response.data)
      } catch (error) {
        console.error('Error fetching centres', error)
      }
    }

    fetchCentres()
  }, [])

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        onSubmit={(values) => {
          // Modify the value before submitting
          const selectedCentre = centres.find(centre => centre.ID_ESCOLA === values.CENTRE)
          const modifiedValues = {
            ...values,
            CENTRE: selectedCentre ? selectedCentre.NOM : values.CENTRE
          }
          handleSubmit(modifiedValues)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          submitForm
        }) => (
          <Form className="flex flex-col w-full space-y-4">
            <div className="grid grid-cols-12 grid-rows-6 gap-6 p-4 m-0 border border-gray-300 shadow rounded-sm-sm print:p-0 bg-gray-50 print:bg-white print:border-0 print:shadow-none">
              <div title="NOM" className="col-span-6 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="NOM"
                  >
                    Nom
                  </label>
                  <ErrorMessage
                    name="NOM"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>
                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.NOM && errors.NOM
                      ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                      : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="NOM"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>
              <div title="CODI" className="col-span-3 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="CODI"
                  >
                    Codi
                  </label>
                  <ErrorMessage
                    name="CODI"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>
                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.CODI && errors.CODI
                      ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                      : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="CODI"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>
              <div title="CENTRE" className="col-span-3 row-span-1">
                 <div className="flex flex-row items-center mb-3">
                   <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="CENTRE"
                  >
                    Centre
                  </label>

                  <ErrorMessage
                    name="CENTRE"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                ${
                  touched.CENTRE && errors.CENTRE
                    ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                    : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                }`}
                  name="CENTRE"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>
              <div title="CCC" className="col-span-3 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="CCC"
                  >
                    CCC
                  </label>
                  <ErrorMessage
                    name="CCC"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>
                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.CCC && errors.CCC
                      ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                      : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="CCC"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>
              <div title="CODI_BECARI" className="col-span-3 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="CODI_BECARI"
                  >
                    Codi Becari
                  </label>
                  <ErrorMessage
                    name="CODI_BECARI"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>
                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.CODI_BECARI && errors.CODI_BECARI
                      ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                      : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="CODI_BECARI"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>
            </div>
            <div className="grid w-full grid-cols-12 pt-4 m-0 gap-y-4">
              <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
                <button
                  alt="GUARDAR"
                  title="GUARDAR"
                  className="flex flex-row items-center justify-center w-full col-span-1 p-2 text-white transition duration-300 bg-green-600 border border-green-700 rounded-sm-sm hover:border-green-300 hover:bg-indigo-200 hover:text-green-700 font-ms-semi"
                  type="submit"
                >
                  <AiOutlineSave size={25} className="mr-2" />
                  <p className="text-base font-semibold">Guardar</p>
                </button>
              </div>
              <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
                {!isNew && (
                  <button
                    alt="ELIMINAR"
                    title="ELIMINAR"
                    className="flex flex-row items-center justify-center w-full p-2 text-white transition duration-300 bg-red-600 border border-red-700 rounded-sm-sm hover:border-red-300 hover:bg-red-200 hover:text-red-700 font-ms-semi"
                    onClick={() => {
                      handleDeleteModal(values.ID_ESCOLA)
                    }}
                    type="button"
                  >
                    <AiOutlineDelete size={25} className="mr-2" />
                    <p className="text-base font-semibold">Eliminar</p>
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

SchoolsFormDetail.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isNew: PropTypes.bool
}

export default SchoolsFormDetail
