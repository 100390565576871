// PropTypes & HOOKS
import PropTypes from 'prop-types'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect, useState } from 'react'

// COMPONENTS
import SinglesFormDetail from 'forms/Singles/SinglesFormDetail'
import { toast } from 'react-toastify'

// CONTEXTS
import { useLocation } from 'wouter'
import { TitleContext } from 'contexts/TitleContext'

// ASSETS
import { ImArrowLeft2 } from 'react-icons/im'

export default function SinglesViewDetail({ params }) {
  // Contextos:
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()
  const [getApiCallDelete, setApiCallDelete] = useFetch()

  // Variables:
  const isNew = !!(params.Id === 'new' && params.registerSingle !== 'register')
  const isMail = !!(params.idMail !== null && params.idMail !== undefined)

  // ############################################################

  // Título (PUT):
  const titleEdit = {
    name: `Modificar Client:`,
    buttons: [
      {
        id: 'btnBack',
        name: <ImArrowLeft2 size={25} />,
        link: isMail ? `/mails/${params.idMail}/mail-split` : `/singles/grid`
      }
    ],
    deleteBtn: true,
    Id: params.Id
  }

  // Título (POST):
  const titleCreate = {
    name: `Registrar Cliente:`,
    buttons: [
      {
        id: 'btnBack',
        name: <ImArrowLeft2 size={25} />,
        link: isMail ? `/mails/${params.idMail}/mail-split` : `/singles/grid`
      }
    ]
  }

  const [fields, setFields] = useState({
    Id: 0,
    Idinterno: params.registerSingle === 'register' ? params.Id : null,
    DNI: null,
    Name: null,
    Surnames: null,
    Email: null
  })

  // CRUD: GET
  useEffect(() => {
    if (!params.registerSingle) {
      if (!isNew) {
        const getApiCall = {
          url: `singles/${params.Id}`,
          method: 'GET',
          messageKo: 'Error leyendo este usuario'
        }
        setApiCallGet(getApiCall)

        setTitle(titleEdit)
      } else {
        setTitle(titleCreate)
      }
    } else {
      setTitle(titleCreate)
    }
  }, [])

  // * Recoger datos API: GET
  useEffect(() => {
    if (getApiCallGet.data) {
      setFields({
        Id: getApiCallGet.data.Id,
        Idinterno: getApiCallGet.data.Idinterno,
        DNI: getApiCallGet.data.DNI,
        Name: getApiCallGet.data.Name,
        Surnames: getApiCallGet.data.Surnames,
        Email: getApiCallGet.data.Email
      })
    }
  }, [getApiCallGet.data])

  // ############################################################

  // CRUD: POST / PUT
  const handleSubmit = async (values) => {
    let url = `singles`
    let method = 'POST'

    if (values.Id !== 0) {
      method = 'PUT'
      url += `/${params.Id}`
    }

    // Siempre los campos en mayúsculas (menos el correo):
    values.Name = values?.Name ? values.Name.toUpperCase() : null
    values.Surnames = values?.Surnames ? values.Surnames.toUpperCase() : null
    values.DNI = values?.DNI ? values.DNI : null
    values.Email = values?.Email ? values.Email : null

    const apiCallPost = {
      url,
      method,
      body: values
    }
    await setApiCallPost(apiCallPost)
  }

  // * Mostrar mensaje API: POST / PUT
  // ! Mostrar error API: POST / PUT
  useEffect(() => {
    if (getApiCallPost.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallPost.message) {
        successMessage(getApiCallPost.message)
        setLocation(
          isMail ? `/mails/${params.idMail}/mail-split` : `/singles/grid`
        )
      }

      if (getApiCallPost.error) {
        errorMessage(getApiCallPost.error)
        setLocation(
          isMail ? `/mails/${params.idMail}/mail-split` : `/singles/grid`
        )
      }
    }
  }, [getApiCallPost.loading])

  // ############################################################

  // CRUD: DELETE
  const handleDelete = async (id) => {
    const apiCallDelete = {
      url: `singles/${id}`,
      method: 'DELETE'
    }
    await setApiCallDelete(apiCallDelete)
  }

  // * Mostrar mensaje API: DELETE
  // ! Mostrar error API: DELETE
  useEffect(() => {
    if (getApiCallDelete.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallDelete.message) {
        successMessage(getApiCallDelete.message)
        setLocation(
          isMail ? `/mails/${params.idMail}/mail-split` : `/singles/grid`
        )
      }

      if (getApiCallDelete.error) {
        errorMessage(getApiCallDelete.error)
        setLocation(
          isMail ? `/mails/${params.idMail}/mail-split` : `/singles/grid`
        )
      }
    }
  }, [getApiCallDelete.loading])

  // ############################################################

  return (
    <>
      <div className="w-full h-full bg-white">
        <div className="w-full mb-3 p-4 print:py-0 print:m-0 bg-gray-200 border-2 border-blue-600 rounded print:border-0">
          <SinglesFormDetail
            handleSubmit={handleSubmit}
            handleDelete={handleDelete}
            fields={fields}
            isNew={isNew}
            isMail={isMail}
            isRegister={
              params.registerSingle !== null &&
              params.registerSingle !== undefined
            }
          />
        </div>
      </div>
    </>
  )
}

SinglesViewDetail.propTypes = {
  params: PropTypes.any
}
