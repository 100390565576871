// COMPONENTS
import LogoGestinet from 'components/ui/Logo/LogoGestinet'

export default function Footer() {
  return (
    <footer className="md:hidden m-auto">
      <LogoGestinet classes="w-28 m-auto mt-2 mb-4" />
    </footer>
  )
}
