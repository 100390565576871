import React, { useState, useEffect, useCallback } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CalendarWithCheckboxes.css';
import useFetch from '../../hooks/useFetch';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const CalendarWithCheckboxes = ({ userId = 2, idEscola = 1, idPractiques = 1 }) => {
    const [date, setDate] = useState(new Date());
    const [festius, setFestius] = useState([]);
    const [checkedDates, setCheckedDates] = useState({});
    const [{ loading, error, data }, fetchAction] = useFetch();
    const [{ data: assistData }, fetchAssistAction] = useFetch();

    const fetchFestius = useCallback(async () => {
        const apiCall = {
            url: `festius/${idEscola}/${userId}`,
            method: 'GET',
            messageKo: 'Error al obtener los días festivos',
        };
        await fetchAction(apiCall);
    }, [idEscola, userId, fetchAction]);

    const fetchAssistencia = useCallback(async () => {
        const apiCall = {
            url: `assistencia/${userId}/${idPractiques}`,
            method: 'GET',
            messageKo: 'Error al obtener los datos de asistencia',
        };
        await fetchAssistAction(apiCall);
    }, [userId, idPractiques, fetchAssistAction]);

    useEffect(() => {
        fetchFestius();
    }, [fetchFestius]);

    useEffect(() => {
        fetchAssistencia();
    }, [fetchAssistencia]);

    useEffect(() => {
        if (data && Array.isArray(data)) {
            const formattedFestius = data.map(f => dayjs(f.FECHA).format('YYYY-MM-DD'));
            setFestius(formattedFestius);
        }
    }, [data]);

    useEffect(() => {
        if (assistData && Array.isArray(assistData)) {
            const initialCheckedDates = {};
            assistData.forEach(({ date, status }) => {
                const formattedDate = dayjs(date).format('YYYY-MM-DD');
                initialCheckedDates[formattedDate] = status === 1;
            });
            setCheckedDates(initialCheckedDates);
        }
    }, [assistData]);

    const formatDate = (date) => {
        return dayjs(date).format('YYYY-MM-DD');
    };

    const handleCheckboxChange = (date) => {
        const formattedDate = formatDate(date);
        setCheckedDates((prev) => ({
            ...prev,
            [formattedDate]: !prev[formattedDate],
        }));
    };

    const tileContent = ({ date, view }) => {
        if (view === 'month') {
            const formattedDate = formatDate(date);
            const isFestiu = festius.includes(formattedDate);
            if (!isFestiu) {
                const isChecked = !!checkedDates[formattedDate];
                return (
                    <div className="checkboxes">
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={() => handleCheckboxChange(date)}
                        />
                    </div>
                );
            }
        }
    };

    let idAssistenciaCounter = 1;

    const handleSave = () => {
        const attendanceData = Object.entries(checkedDates).map(([date, status]) => ({
            idAssistencia: idAssistenciaCounter++, // Incrementa el ID de asistencia
            date: formatDate(date), // Formatea la fecha
            status,
        }));

        const apiCall = {
            url: 'assistencia',
            method: 'POST',
            messageKo: 'Error al guardar datos de asistencia',
            messageOk: 'Datos de asistencia guardados exitosamente',
            body: {
                userId,
                idPractiques,
                attendance: attendanceData,
            },
        };

        fetchAction(apiCall);
    };

    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            const formattedDate = formatDate(date);
            if (festius.includes(formattedDate)) {
                return 'festiu'; // Añade una clase 'festiu' a los días festivos
            }
            if (checkedDates[formattedDate]) {
                return 'checked-day'; // Añade una clase 'checked-day' a los días con checkbox marcado
            }
        }
        return null;
    };

    if (loading) {
        return <p>Cargando calendario...</p>;
    }

    return (
        <div className="calendar-container">
            <Calendar
                onChange={setDate}
                value={date}
                tileContent={tileContent}
                tileClassName={tileClassName}
            />
            <button onClick={handleSave} disabled={loading}>
                {loading ? 'Guardando...' : 'Guardar'}
            </button>
            {error && <p className="error-message">{error.message}</p>}
        </div>
    );
};

CalendarWithCheckboxes.propTypes = {
    userId: PropTypes.number,
    idEscola: PropTypes.number,
    idPractiques: PropTypes.number,
};

export default CalendarWithCheckboxes;

