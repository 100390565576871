// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useContext } from 'react'

// FORMIK HOOK
import { Formik, Form, Field } from 'formik'

// CONTEXT
import { ModalContext } from 'contexts/ModalContext'

// ICONS
import { MdSend, MdClose } from 'react-icons/md'

export default function SendMailModal({ params }) {
  /**
   * @param isModalOpen - abrir modal
   * @param closeModal  - cerrar modal
   * @param values      - valores a enviar por correo
   * @param handleSend  - función para enviar
   * @param handleSend  - true (enviamiento masico) o false (enviamiento individual)
   */
  const { isModalOpen, closeModal } = useContext(ModalContext)
  const { values, handleSend, isAll } = useContext(ModalContext).getModalData

  // Bloqueamos el scroll:
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  window.onscroll = function () {
    window.scrollTo(scrollLeft, scrollTop)
  }

  return (
    <>
      <div
        className="w-screen h-screen fixed bg-black bg-opacity-75 top-0 left-0 z-50 flex justify-center items-center"
        onClick={() => {
          closeModal()
          window.onscroll = function () {}
        }}
      />

      <Formik
        enableReinitialize={true}
        initialValues={values}
        onSubmit={(values) => {
          closeModal()
          window.onscroll = function () {}
          handleSend(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          submitForm
        }) => (
          <Form className="w-3/4 m-auto justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-6xl">
              <div
                className="w-full border-2 border-teal-600 rounded-md shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none
              divide-y divide-dashed divide-teal-200"
              >
                {/* CABECERA */}
                <div className="flex items-center justify-between p-5">
                  <h3 className="text-2xl font-semibold">
                    Se enviará a{' '}
                    <b>{isAll ? 'TODOS' : values.MailSplit_NameSingle}</b>
                  </h3>
                  <button
                    className="transition duration-300 
                      border-red-600 bg-red-500 text-white hover:border-red-400 hover:bg-red-300 hover:text-red-600
                      font-ms-semi py-1 px-1 border-2 rounded 
                      flex justify-center items-center"
                    type="button"
                    onClick={() => {
                      closeModal()
                      window.onscroll = function () {}
                    }}
                  >
                    <MdClose size={25} />
                  </button>
                </div>

                {/* CUERPO */}
                <div className="w-full flex flex-col justify-center items-center p-6 justify-between">
                  {/* Campo 'Asunto': */}
                  <div className="w-full py-2 px-3">
                    <label
                      className="block mr-2 text-teal-800 text-base font-ms-semi font-bold mb-2"
                      htmlFor={
                        isAll ? 'Mails_Subject' : 'MailSplit_SubjectMail'
                      }
                    >
                      Asunto:
                    </label>

                    <Field
                      className="pl-2 rounded w-full appearance-none px-3 p-2 border-2 border-gray-300 outline-none text-grey"
                      name={isAll ? 'Mails_Subject' : 'MailSplit_SubjectMail'}
                      type="text"
                      style={{ paddingTop: '9px', paddingBottom: '9px' }}
                    />
                  </div>

                  {/* Campo 'Asunto': */}
                  <div className="w-full py-2 px-3 items-center col-span-6">
                    <label
                      className="block mr-2 text-teal-800 text-base font-ms-semi font-bold mb-2"
                      htmlFor={
                        isAll ? 'Mails_Message' : 'MailSplit_MessageMail'
                      }
                    >
                      Mensaje adjunto al cliente:
                    </label>

                    <Field
                      className="pl-2 rounded w-full appearance-none px-3 p-2 border-2 border-gray-300 outline-none text-grey"
                      name={isAll ? 'Mails_Message' : 'MailSplit_MessageMail'}
                      type="text"
                      style={{ paddingTop: '9px', paddingBottom: '9px' }}
                    />
                  </div>

                  {/* Campo 'Asunto': */}
                  <div className="w-full py-2 px-3 items-center col-span-6">
                    <label
                      className="block mr-2 text-teal-800 text-base font-ms-semi font-bold mb-2"
                      htmlFor={
                        isAll ? 'Mails_Comment' : 'MailSplit_CommentMail'
                      }
                    >
                      Comentario addicional:
                    </label>

                    <Field
                      className="pl-2 rounded w-full appearance-none px-3 p-2 border-2 border-gray-300 outline-none text-grey"
                      name={isAll ? 'Mails_Comment' : 'MailSplit_CommentMail'}
                      type="text"
                      style={{ paddingTop: '9px', paddingBottom: '9px' }}
                    />
                  </div>
                </div>

                {/* PIE */}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="transition duration-300 
                      border-teal-600 bg-teal-500 text-white hover:border-teal-400 hover:bg-teal-300 hover:text-teal-600
                      font-ms-semi py-2 px-6 border-2 rounded 
                      flex justify-center items-center"
                    type="submit"
                  >
                    <MdSend size={25} />
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

SendMailModal.propTypes = {
  params: PropTypes.any
}
