// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

// COMPONENTS
import TeachersTable from 'components/ui/Table/TeachersTable'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

export default function TeachersCompGrid({ items, handleClick }) {
  const [getTableData, setTableData] = useState([])

  const columns = [
    {
      Header: 'Nom',
      accessor: 'NOM',
      Filter: ColumnFilter,
      Type: 'name',
      Key: 'name'
    },
    {
      Header: 'Cognom',
      accessor: 'COGNOM',
      Filter: ColumnFilter,
      Type: 'surname',
      Key: 'surname'
    },
    {
      Header: 'Escola',
      accessor: 'ESCOLA_NOM',
      Filter: ColumnFilter,
      Type: 'school',
      Key: 'school'
    }
  ]

  useEffect(() => {
    if (items) {
      const itemsData = items.map((item) => ({
        ID_PROFESSOR: item.ID_PROFESSOR,
        NOM: item.NOM,
        COGNOM: item.COGNOM,
        ESCOLA_NOM: item.ESCOLA_NOM
      }))
      setTableData(itemsData)
    }
  }, [items])

  return (
    <div className="overflow-x-auto shadow-md print:shadow-none">
      {getTableData.length > 0 && (
        <TeachersTable
          data={getTableData}
          columns={columns}
          handleClick={handleClick}
        />
      )}
    </div>
  )
}

TeachersCompGrid.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  value: PropTypes.any
}
