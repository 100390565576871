// HOOKS
import { Link } from 'wouter'
import { useContext } from 'react'

// CONTEXTS
import { TitleContext } from 'contexts/TitleContext'

export default function Title() {
  const { title } = useContext(TitleContext)

  return (
    <header className="bg-customBar border-b-2 border-indigo-950 flex print:hidden">
      <div className="w-full mx-auto py-1 px-6">
        <h1 className="text-2xl font-medium text-gray-100">{title.name}</h1>
      </div>
      {title.buttons && (
        <div className="w-full flex flex-row justify-end items-center mx-auto py-1 pr-2 text-right titleRightButton">
          {title.btnSendAll && (
            <button
              type="button"
              className="ml-2 h-full px-5 font-semibold transition-colors duration-150 bg-blue-100 border-2 border-blue-600 text-blue-600 rounded focus:shadow-outline hover:bg-blue-600 hover:text-white"
              onClick={() => {
                title.handleSendModal()
              }}
            >
              {title.btnSendAllText}
            </button>
          )}

          {title.buttons.map((button) => {
            return button.id === 'btnBack' ? (
              <Link to={button.link} key={button.name}>
                <button
                  type="button"
                  className="ml-2 h-full px-5 font-semibold transition-colors duration-150 bg-red-100 border-2 border-red-600 text-red-600 rounded focus:shadow-outline hover:bg-red-600 hover:text-white"
                >
                  {button.name}
                </button>
              </Link>
            ) : (
              <Link to={button.link} key={button.name}>
                <button
                  type="button"
                  className="ml-2 h-full px-5 font-semibold transition-colors duration-150 bg-blue-100 border-2 border-blue-600 text-blue-600 rounded focus:shadow-outline hover:bg-blue-600 hover:text-white"
                >
                  {button.name}
                </button>
              </Link>
            )
          })}
        </div>
      )}
    </header>
  )
}
