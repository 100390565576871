import { useContext, useEffect } from 'react';
import useFetch from 'hooks/useFetch';

// COMPONENTS
import { TitleContext } from 'contexts/TitleContext';
import { UserContext } from 'contexts/UserContext';

import DashboardSchools from 'components/Dashboard/DashbordSchools';
import DashboardTeachers from 'components/Dashboard/DashbordTeachers';
import DashboardStudents from 'components/Dashboard/DashbordStudents';

// ASSETS
import loadingIco from 'components/ui/Logo/loading_azul.gif';

export default function DashboardPage() {
  // Contextos:
  const { setTitle } = useContext(TitleContext);
  const { user } = useContext(UserContext);

  // useFetch():
  const [getApiCallGetSchools, setApiCallGetSchools] = useFetch();
  const [getApiCallGetTeachers, setApiCallGetTeachers] = useFetch();
  const [getApiCallGetStudents, setApiCallGetStudents] = useFetch();

  // ############################################################

  useEffect(() => {
    const dashboardTitle = { name: 'Panell - Resum', buttons: [] };
    setTitle(dashboardTitle);

    if (user.role_id === 2) {
      const getApiCallSchools = {
        url: 'dashbord/schools/total',
        method: 'GET',
        messageKo: 'Error al obtener el total de escuelas.'
      };
      setApiCallGetSchools(getApiCallSchools);

      const getApiCallTeachers = {
        url: 'dashbord/teachers/total',
        method: 'GET',
        messageKo: 'Error al obtener el total de profesores.'
      };
      setApiCallGetTeachers(getApiCallTeachers);

      const getApiCallStudents = {
        url: 'dashbord/students/total',
        method: 'GET',
        messageKo: 'Error al obtener el total de estudiantes.'
      };
      setApiCallGetStudents(getApiCallStudents);
    } else if (user.role_id === 1) {
      // Fetch students for a specific teacher
      const getApiCallStudents = {
        url: `dashbord/studentsbyschl/total/${user.id}`,
        method: 'GET',
        messageKo: 'Error al obtener el total de estudiantes de la escuela del profesor.'
      };
      setApiCallGetStudents(getApiCallStudents);
    }
  }, [setTitle, setApiCallGetSchools, setApiCallGetTeachers, setApiCallGetStudents, user]);

  // ############################################################

  const isLoading = user.role_id === 2
    ? !getApiCallGetSchools.data || !getApiCallGetTeachers.data || !getApiCallGetStudents.data
    : !getApiCallGetStudents.data;

  return !isLoading ? (
    <div>
      <section className="bg-graisy grid gap-y-2 grid-cols-1 sm:grid-cols-1 p-2 w-full mr-4 h-5/6">
        <article className="flex flex-wrap">
          {user.role_id === 2 && (
            <>
              <DashboardSchools fieldsDashSchools={getApiCallGetSchools.data} />
              <DashboardTeachers fieldsDashTeachers={getApiCallGetTeachers.data} />
            </>
          )}
          <DashboardStudents fieldsDashStudents={getApiCallGetStudents.data} />
        </article>
        <p className="text-white">.</p>
        <br />
      </section>
    </div>
  ) : (
    <div>
      <div className="h-full w-full flex justify-center items-center content-center">
        <p
          className="h-full text-indigo-800 font-extrabold flex justify-center flex-col-reverse items-center"
          title="Cargando..."
        >
          ¡Carregant!
          <div className="mt-16 mb-8" style={{ width: '75px', height: '75px' }}>
            <img src={loadingIco} alt="Loading..." />
          </div>
        </p>
      </div>
    </div>
  );
}
