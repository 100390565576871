// PropTypes & HOOKS
import PropTypes from 'prop-types'
import React, { createContext, useState } from 'react'

export const TitleContext = createContext(null)

export const TitleContextProvider = ({ children }) => {
  const [title, setTitle] = useState({})

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {children}
    </TitleContext.Provider>
  )
}

TitleContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}
