// PropTypes & React HOOKS
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

// COMPONENTS
import MailsTable from 'components/ui/Table/MailsTable'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

export default function MailsCompGrid({ items, handleClick, handleDelete }) {
  const [getTableData, setTableData] = useState([])

  // Columnas:
  const columns = [
    {
      Header: 'Nº PDF',
      accessor: 'Mails_Id',
      Filter: ColumnFilter
    },
    {
      Header: 'Fecha',
      accessor: 'Mails_DateMail',
      Filter: ColumnFilter
    },
    {
      Header: 'Asunto',
      accessor: 'Mails_Subject',
      Filter: ColumnFilter
    },
    {
      Header: 'Mensaje',
      accessor: 'Mails_Message',
      Filter: ColumnFilter
    },
    {
      Header: 'Eliminar',
      Filter: ColumnFilter
    }
  ]

  // Adaptar datos para la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Mails_Id: item.id,
          Mails_DateMail: item.DateMail,
          Mails_Subject: item.Subject,
          Mails_Message: item.Message,
          Mails_Comment: null
        })
      })
      setTableData(itemsData)
    }
  }, [items])

  return (
    <div className="overflow-x-auto">
      <div className="align-middle inline-block min-w-full">
        <div className="shadow overflow-hidden border-b border-gray-200">
          {getTableData.length > 0 && (
            <MailsTable
              data={getTableData}
              columns={columns}
              handleClick={handleClick}
              handleDelete={handleDelete}
            />
          )}
        </div>
      </div>
    </div>
  )
}

MailsCompGrid.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  handleDelete: PropTypes.func
}
