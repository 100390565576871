import PropTypes from 'prop-types'

// Images
import logoCodina from 'assets/img/logo-codina-web.png'


export default function LogoAula({ link }) {
  return (
    <a href={link} className="h-full flex flex-row justify-around items-center">
      <div className="h-full w-5/5 ml-6 flex items-center">
        <img className="w-9/12 max-w-md" src={logoCodina} />
      </div>
    </a>
  )
}
LogoAula.propTypes = {
  link: PropTypes.string
}
