// PropTypes & HOOKS
import PropTypes from 'prop-types'

// FORMIK HOOK
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

// CONTEXTS
import { useContext } from 'react'
import { ModalContext } from 'contexts/ModalContext'

// ASSETS
import {
  AiOutlinePrinter,
  AiOutlineSave,
  AiOutlineDelete
} from 'react-icons/ai'

// Print function:
function handlePrintSheet() {
  window.print()
}

const SinglesFormDetail = ({
  handleSubmit,
  handleDelete,
  fields,
  isNew,
  isMail,
  isRegister
}) => {
  // Contextos:
  const { openModal } = useContext(ModalContext)

  // Esquema validación formulario:
  const validationSchema = Yup.object().shape({
    Idinterno: Yup.number()
      .nullable('(*)')
      .required('(*)')
      .typeError('(* Campo numérico)'),
    DNI: Yup.string()
      .nullable('(*)')
      .required('(*)')
      .matches(/^[A-Z]?\d{7,8}[A-Z]?$/, '(* Ejemplos: 00000000A, A00000000)'),
    Name: Yup.string()
      .nullable('(*)')
      .required('(*)')
      .min(2, '(* Muy corto)')
      .max(100, '(* Muy largo)'),
    Surnames: Yup.string()
      .nullable('(*)')
      .required('(*)')
      .min(2, '(* Muy corto)')
      .max(100, '(* Muy largo)'),
    Email: Yup.string()
      .nullable('(*)')
      .required('(*)')
      .email('(* Formato erróneo)')
      .min(2, '(*)')
      .max(100, '(*)')
  })

  // Modal confirmación eliminación:
  const handleDeleteModal = (id) => {
    const modalData = {
      id,
      handleDelete,
      type: 'DELETE'
    }

    openModal(modalData)
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        validationSchema={!isMail && validationSchema}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          submitForm
        }) => (
          <Form className="flex flex-col" encType="multipart/form-data">
            <div className="grid grid-cols-1 auto-rows-auto p-4 m-0">
              {/* Primer contenedor => Campos: */}
              <div className="w-full grid grid-cols-2 gap-y-4 gap-x-3 pb-4 justify-between">
                {/* Campo 'Identificador Interno': */}
                <div className="w-full bg-gray-200 py-2 px-3 items-center col-span-1">
                  <div className="flex flex-row items-center mb-3">
                    <label
                      className="block mr-2 text-blue-700 text-base font-ms-semi font-bold leading-4 print:mt-4"
                      htmlFor="Idinterno"
                    >
                      Identificador Interno
                    </label>

                    <ErrorMessage
                      name="Idinterno"
                      render={(message) => (
                        <span className="text-sm text-red-600 font-bold leading-4">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded w-full appearance-none px-3 p-2 border-2 outline-none text-grey
                      print:text-xs print:shadow-none print:border-0 print:border-b print:border-blue-700 print:rounded-none 
                      print:outline-none print:pl-0
                      ${
                        touched.Idinterno && errors.Idinterno
                          ? 'border-red-200 bg-red-50'
                          : 'border-gray-300'
                      }
                      ${isMail && 'cursor-not-allowed'}`}
                    name="Idinterno"
                    type="text"
                    style={{
                      backgroundColor: isMail && '#f2f2f2',
                      color: isMail && '#999999'
                    }}
                    disabled={isMail}
                  />
                </div>

                {/* Campo 'DNI': */}
                <div className="w-full bg-gray-200 py-2 px-3 items-center col-span-1">
                  <div className="flex flex-row items-center mb-3">
                    <label
                      className="block mr-2 text-blue-700 text-base font-ms-semi font-bold leading-4 print:mt-4"
                      htmlFor="DNI"
                    >
                      DNI
                    </label>

                    <ErrorMessage
                      name="DNI"
                      render={(message) => (
                        <span className="text-sm text-red-600 font-bold leading-4">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded w-full appearance-none px-3 p-2 border-2 outline-none text-grey
                      print:text-xs print:shadow-none print:border-0 print:border-b print:border-blue-700 print:rounded-none 
                      print:outline-none print:pl-0
                      ${
                        touched.DNI && errors.DNI
                          ? 'border-red-200 bg-red-50'
                          : 'border-gray-300'
                      }
                      ${isMail && !isRegister && 'cursor-not-allowed'}`}
                    name="DNI"
                    type="text"
                    style={{
                      backgroundColor: isMail && !isRegister && '#f2f2f2',
                      color: isMail && !isRegister && '#999999'
                    }}
                    disabled={isMail && !isRegister}
                  />
                </div>

                {/* Campo 'Nombre': */}
                <div className="w-full bg-gray-200 py-2 px-3 items-center col-span-1">
                  <div className="flex flex-row items-center mb-3">
                    <label
                      className="block mr-2 text-blue-700 text-base font-ms-semi font-bold leading-4 print:mt-4"
                      htmlFor="Name"
                    >
                      Nombre
                    </label>

                    <ErrorMessage
                      name="Name"
                      render={(message) => (
                        <span className="text-sm text-red-600 font-bold leading-4">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded w-full appearance-none px-3 p-2 border-2 outline-none text-grey
                      print:text-xs print:shadow-none print:border-0 print:border-b print:border-blue-700 print:rounded-none 
                      print:outline-none print:pl-0
                      ${
                        touched.Name && errors.Name
                          ? 'border-red-200 bg-red-50'
                          : 'border-gray-300'
                      }`}
                    name="Name"
                    type="text"
                  />
                </div>

                {/* Campo 'Apellidos': */}
                <div className="w-full bg-gray-200 py-2 px-3 items-center col-span-1">
                  <div className="flex flex-row items-center mb-3">
                    <label
                      className="block mr-2 text-blue-700 text-base font-ms-semi font-bold leading-4 print:mt-4"
                      htmlFor="Surnames"
                    >
                      Apellidos
                    </label>

                    <ErrorMessage
                      name="Surnames"
                      render={(message) => (
                        <span className="text-sm text-red-600 font-bold leading-4">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded w-full appearance-none px-3 p-2 border-2 outline-none text-grey
                      print:text-xs print:shadow-none print:border-0 print:border-b print:border-blue-700 print:rounded-none 
                      print:outline-none print:pl-0
                      ${
                        touched.Surnames && errors.Surnames
                          ? 'border-red-200 bg-red-50'
                          : 'border-gray-300'
                      }`}
                    name="Surnames"
                    type="text"
                  />
                </div>

                {/* Campo 'Correo': */}
                <div className="w-full bg-gray-200 py-2 px-3 items-center col-span-1">
                  <div className="flex flex-row items-center mb-3">
                    <label
                      className="block mr-2 text-blue-700 text-base font-ms-semi font-bold leading-4 print:mt-4"
                      htmlFor="Email"
                    >
                      Correo
                    </label>

                    <ErrorMessage
                      name="Email"
                      render={(message) => (
                        <span className="text-sm text-red-600 font-bold leading-4">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded w-full appearance-none px-3 p-2 border-2 outline-none text-grey
                      print:text-xs print:shadow-none print:border-0 print:border-b print:border-blue-700 print:rounded-none 
                      print:outline-none print:pl-0
                      ${
                        touched.Email && errors.Email
                          ? 'border-red-200 bg-red-50'
                          : 'border-gray-300'
                      }`}
                    name="Email"
                    type="text"
                  />
                </div>
              </div>

              {/* Segundo contenedor => Botones: */}
              <div className="w-full flex flex-row px-3 mt-2 justify-between">
                <div>
                  {/* Botón 'Eliminar': */}
                  {!isNew && !isMail && (
                    <button
                      className={`transition duration-300 
                        border-red-700 bg-red-600 text-white 
                        hover:border-red-400
                        hover:bg-red-300 
                        hover:text-red-700
                        font-ms-semi py-3 px-10 my-2 border-2 rounded 
                        flex flex-row print:hidden`}
                      type="button"
                      onClick={(e) => {
                        handleDeleteModal(values.Id)
                      }}
                      alt="ELIMINAR"
                      title="ELIMINAR"
                    >
                      <AiOutlineDelete size={25} className="mr-2" />
                      <p className="font-semibold text-base">Eliminar</p>
                    </button>
                  )}
                </div>

                <div className="flex flex-row justify-end">
                  {/* Botón 'Imprimir': */}
                  {!isNew && !isMail && (
                    <button
                      className={`ml-5 transition duration-300 
                        border-amber-700 bg-amber-600 text-white 
                        hover:border-amber-400
                        hover:bg-amber-300 
                        hover:text-amber-700
                        font-ms-semi py-3 px-10 my-2 border-2 rounded 
                        flex flex-row print:hidden`}
                      type="button"
                      onClick={handlePrintSheet}
                      alt="IMPRIMIR"
                      title="IMPRIMIR"
                    >
                      <AiOutlinePrinter size={25} className="mr-2" />
                      <p className="font-semibold text-base">Imprimir</p>
                    </button>
                  )}

                  {/* Botón 'Guardar': */}
                  <button
                    className={`ml-5 transition duration-300 
                      border-green-700 bg-green-600 text-white 
                      hover:border-green-400
                      hover:bg-green-300 
                      hover:text-green-700
                      font-ms-semi py-3 px-10 my-2 border-2 rounded 
                      flex flex-row print:hidden`}
                    type="submit"
                    alt="GUARDAR"
                    title="GUARDAR"
                  >
                    <AiOutlineSave size={25} className="mr-2" />
                    <p className="font-semibold text-base">Guardar</p>
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

SinglesFormDetail.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  renderTableData: PropTypes.func,
  fields: PropTypes.object,
  isUpdate: PropTypes.any,
  isNew: PropTypes.bool,
  isMail: PropTypes.bool,
  isRegister: PropTypes.bool
}

export default SinglesFormDetail
