// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { createRef, useState } from 'react'

// FORMIK HOOK
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
// import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'
// import es from 'date-fns/locale/es'

// ASSETS
import { AiOutlineSave } from 'react-icons/ai'

const MailsFormDetail = ({ handleSubmit, fields }) => {
  // Estados:
  const [getFileName, setFileName] = useState(null)
  const [getOpenFileLink, setOpenFileLink] = useState(null)
  const [startDate, setStartDate] = useState(new Date())

  // Referencias:
  const hiddenFileInput = createRef()
  const handleClickFile = () => {
    hiddenFileInput.current.click()
  }

  // Cambiar nombre documento introducido:
  const changeFileName = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFileName(e.target.files[0].name)
      setOpenFileLink(URL.createObjectURL(e.target.files[0]))
    }
  }

  // Esquema validación formulario:
  const validationSchema = Yup.object().shape({
    Subject: Yup.string()
      .required('(*)')
      .nullable('(*)')
      .min(2, '(* Muy corto)')
      .max(100, '(* Muy largo)'),
    Message: Yup.string()
      .required('(*)')
      .nullable('(*)')
      .min(2, '(* Muy corto)')
      .max(500, '(* Muy largo)'),
    DateMail: Yup.string().required('(*)'),
    Document: Yup.mixed()
      .required('(*)')
      .test(
        'fileType',
        '(* Solo archivos .pdf)',
        (value) => value && value.type === 'application/pdf'
      )
  })

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={{ ...fields }}
      onSubmit={(values) => {
        handleSubmit(values, hiddenFileInput, false)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        submitForm
      }) => (
        <Form className="flex flex-col" encType="multipart/form-data">
          <div className="grid grid-cols-1 auto-rows-auto p-4 m-0">
            {/* Primera fila => Campo 'Asunto': */}
            <div className="w-full grid grid-cols-8 pb-4 justify-between">
              {/* Campo 'Asunto': */}
              <div className="w-full bg-gray-200 py-2 px-3 items-center col-span-6">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-blue-700 text-base font-ms-semi font-bold leading-4"
                    htmlFor="Subject"
                  >
                    Asunto
                  </label>

                  <ErrorMessage
                    name="Subject"
                    render={(message) => (
                      <span className="text-sm text-red-600 font-bold leading-4">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded w-full appearance-none px-3 p-2 border-2 outline-none text-grey
                    ${
                      touched.Subject && errors.Subject
                        ? 'border-red-200 bg-red-50'
                        : 'border-gray-300'
                    }`}
                  name="Subject"
                  type="text"
                />
              </div>

              {/* Campo 'Data': */}
              <div className="w-full bg-gray-200 py-2 px-3 items-center col-span-2">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-blue-700 text-base font-ms-semi font-bold leading-4"
                    htmlFor="DateMail"
                  >
                    Fecha
                  </label>

                  <ErrorMessage
                    name="DateMail"
                    render={(message) => (
                      <span className="text-sm text-red-600 font-bold leading-4">
                        {message}
                      </span>
                    )}
                  />
                </div>

                {/* <DatePicker
                  className={`pl-2 rounded w-full appearance-none py-2 px-3 border-2 border-gray-300 outline-none text-grey-darker
                    ${
                      touched.DateMail && errors.DateMail
                        ? 'border-red-200 bg-red-50'
                        : 'border-gray-300'
                    }`}
                  name="DateMail"
                  locale={es}
                  selected={startDate}
                  onChange={(date) => {
                    const transformedDate =
                      String(date.getMonth() + 1).padStart(2, '0') +
                      '/' +
                      date.getFullYear()

                    setFieldValue('DateMail', transformedDate)
                    setStartDate(date)
                  }}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  style={{ paddingTop: '8px', paddingBottom: '8px' }}
                /> */}
              </div>
            </div>

            {/* Segunda fila => Campo 'Mensaje adjunto al cliente': */}
            <div className="w-full flex flex-row pb-4 justify-between">
              {/* Campo 'Mensaje adjunto al cliente': */}
              <div className="w-full col-span-6 bg-gray-200 pt-2 px-3 items-center">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-blue-700 text-base font-ms-semi font-bold leading-4"
                    htmlFor="Message"
                  >
                    Mensaje adjunto al cliente
                  </label>

                  <ErrorMessage
                    name="Message"
                    render={(message) => (
                      <span className="text-sm text-red-600 font-bold leading-4">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 h-40 rounded w-full appearance-none px-3 p-2 border-2 border-gray-300 outline-none text-grey
                    ${
                      touched.Message && errors.Message
                        ? 'border-red-200 bg-red-50'
                        : 'border-gray-300'
                    }`}
                  name="Message"
                  type="text"
                  component="textarea"
                />
              </div>
            </div>

            {/* Tercera fila => Campo 'Documento': */}
            <div className="w-full flex flex-row pb-6 justify-between">
              {/* Campo 'Documento': */}
              <div className="w-full bg-gray-200 py-2 px-3 items-center">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-blue-700 text-base font-ms-semi font-bold leading-4"
                    htmlFor="FileMail"
                  >
                    Documento
                  </label>

                  <ErrorMessage
                    name="Document"
                    render={(message) => (
                      <span className="text-sm text-red-600 font-bold leading-4">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <div className="w-full grid grid-cols-12 gap-x-8">
                  <button
                    className="col-span-2 w-full block font-base font-medium py-2 px-6 border-2 rounded border-blue-400 bg-blue-200 
                    hover:border-blue-600 hover:bg-white hover:text-blue-700 cursor-pointer"
                    type="button"
                    onClick={handleClickFile}
                    style={{ padding: '9px' }}
                  >
                    Seleccionar
                  </button>

                  <button
                    className="col-span-2 w-full block font-base font-medium py-2 px-6 border-2 rounded border-red-400 bg-red-200 
                    hover:border-red-600 hover:bg-white hover:text-red-700 cursor-pointer"
                    type="button"
                    onClick={() => {
                      setFileName(null)
                      setFieldValue('FileName', null)
                      setFieldValue('FilePath', null)
                    }}
                    style={{ padding: '9px' }}
                  >
                    Borrar
                  </button>

                  <div
                    className={`col-span-8 h-13 rounded w-full appearance-none border-2 border-gray-300 outline-none text-grey bg-white
                      ${
                        touched.Document && errors.Document
                          ? 'border-red-200 bg-red-50'
                          : 'border-gray-300'
                      }`}
                    type="file"
                    ref={hiddenFileInput}
                    style={{ padding: '9px' }}
                  >
                    {getFileName && (
                      <a
                        className={`${
                          getFileName &&
                          'py-0.5 px-6 rounded border border-blue-400 bg-blue-200 hover:border-blue-600 hover:bg-gray-100 hover:text-blue-700 cursor-pointer'
                        }`}
                        href={`${
                          !getOpenFileLink
                            ? `http://motormunich.com.mialias.net/uploads/mail_${
                                values.Id
                              }/${encodeURIComponent(getFileName)}`
                            : getOpenFileLink
                        }`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {getFileName}
                      </a>
                    )}
                  </div>

                  <input
                    className="hidden col-span-3 rounded w-full appearance-none border-2 border-gray-300 outline-none text-grey bg-white"
                    onChange={(e) => {
                      setFieldValue('FilePath', null)
                      changeFileName(e)
                      setFieldValue('Document', e.currentTarget.files[0])
                    }}
                    name="Document"
                    type="file"
                    accept="application/pdf"
                    ref={hiddenFileInput}
                    style={{ padding: '9px' }}
                  />
                </div>
              </div>
            </div>

            {/* Cuarta fila => Botones: */}
            <div className="w-full flex flex-row px-3 justify-end">
              {/* Botón 'Guardar': */}
              <button
                className={`ml-5 transition duration-300 
                  border-green-700 bg-green-600 text-white 
                  hover:border-green-400
                  hover:bg-green-300 
                  hover:text-green-700
                  font-ms-semi py-3 px-10 my-2 border-2 rounded 
                  flex flex-row print:hidden`}
                type="submit"
                alt="GUARDAR"
                title="GUARDAR"
              >
                <AiOutlineSave size={25} className="mr-2" />
                <p className="font-semibold text-base">Guardar</p>
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

MailsFormDetail.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isNew: PropTypes.bool
}

export default MailsFormDetail
