// import PropTypes from 'prop-types';
// import CheckedIcon from 'assets/icons/checked-ok-mark-accept-check-approved-svgrepo-com.svg';
// import ErrorIcon from 'assets/icons/error-warning-danger-problem-attention-svgrepo-com.svg'; // Asegúrate de que la ruta sea correcta
// import InterrogateIcon from 'assets/icons/help-support-information-question-faq-svgrepo-com.svg'

// const CheckCell = ({ value }) => {
//   return (
//     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
//       {value === 1 
//         ? <img src={CheckedIcon} alt="ok" style={{ width: '24px', height: '24px' }} /> 
//         : <img src={ErrorIcon} alt="ko" style={{ width: '24px', height: '24px' }} />}
//     </div>
//   );
// };

// CheckCell.propTypes = {
//   value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
// };

// export default CheckCell;


import PropTypes from 'prop-types';
import CheckedIcon from 'assets/icons/checked-ok-mark-accept-check-approved-svgrepo-com.svg';
import ErrorIcon from 'assets/icons/error-warning-danger-problem-attention-svgrepo-com.svg';
import InterrogateIcon from 'assets/icons/help-support-information-question-faq-svgrepo-com.svg'; // Asegúrate de que la ruta sea correcta

const CheckCell = ({ value }) => {
  let icon = null;
  let altText = '';

  if (value === 1) {
    icon = CheckedIcon;
    altText = "ok";
  } else if (value === 0) {
    icon = ErrorIcon;
    altText = "ko";
  } else if (value === 2) {
    icon = InterrogateIcon;
    altText = "pendent";
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      {icon && <img src={icon} alt={altText} style={{ width: '24px', height: '24px' }} />}
    </div>
  );
};

CheckCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default CheckCell;
