import React, { useState, useEffect, useCallback } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CalendarHolidays.css';
import useFetch from '../../hooks/useFetch';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const CalendarWithSchoolHolidays = ({ idEscola = 1 }) => {
    const [date, setDate] = useState(new Date());
    const [checkedDates, setCheckedDates] = useState({});
    const [{ loading, error, data }, fetchAction] = useFetch();

    useEffect(() => {
        // Log para verificar la llamada al API
        console.log('Fetching school holidays...');
        fetchAction({
            url: `festius/${idEscola}`,
            method: 'GET',
            messageKo: 'Error al obtener los días festivos',
        });
    }, [idEscola, fetchAction]);

    useEffect(() => {
        if (data && Array.isArray(data)) {
            const initialCheckedDates = {};
            data.forEach(({ FECHA, NOM }) => {
                const formattedDate = dayjs(FECHA).format('YYYY-MM-DD');
                initialCheckedDates[formattedDate] = { checked: true, nom: NOM };
            });
            setCheckedDates(initialCheckedDates);
        }
    }, [data]);

    const formatDate = (date) => {
        return dayjs(date).format('YYYY-MM-DD');
    };

    const handleCheckboxChange = (date) => {
        const formattedDate = formatDate(date);
        setCheckedDates((prev) => ({
            ...prev,
            [formattedDate]: { checked: !prev[formattedDate]?.checked, nom: prev[formattedDate]?.nom || '' },
        }));
    };

    const handleSelectChange = (date, nom) => {
        const formattedDate = formatDate(date);
        setCheckedDates((prev) => ({
            ...prev,
            [formattedDate]: { ...prev[formattedDate], nom },
        }));
    };

    const handleSave = () => {
        const festiusData = Object.entries(checkedDates)
            .filter(([_, { checked }]) => checked)
            .map(([date, { nom }], index) => ({
                idFestiu: index + 1, // Incrementa el ID de festivo
                nom,
                fecha: date,
            }));

        console.log('Guardant Festius:', festiusData); // Log para verificar los datos que se van a enviar

        const apiCall = {
            url: 'festius',
            method: 'POST',
            messageKo: 'Error al guardar los días festivos',
            messageOk: 'Días festivos guardados exitosamente',
            body: {
                idEscola,
                festius: festiusData,
            },
        };

        fetchAction(apiCall);
    };

    const tileContent = ({ date, view }) => {
        if (view === 'month') {
            const formattedDate = formatDate(date);
            const isChecked = !!checkedDates[formattedDate]?.checked;
            const nom = checkedDates[formattedDate]?.nom || '';
            return (
                <div className="checkboxes">
                    <input
                        className="holiday-select"
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(date)}
                    />
                    {isChecked && (
                        <select
                            value={nom}
                            onChange={(e) => handleSelectChange(date, e.target.value)}
                        >
                            <option value="">Selecciona un tipo de festivo</option>
                            <option value="Festa Lliure Disposició">Festa Lliure Disposició</option>
                            <option value="Festa Nacional">Festa Nacional</option>
                            <option value="Festa Local">Festa Local</option>
                        </select>
                    )}
                </div>
            );
        }
        return null;
    };

    if (loading) {
        return <p>Cargando calendario...</p>;
    }

    return (
        <div className="calendar-container">
            <Calendar
                onChange={setDate}
                value={date}
                tileContent={tileContent}
            />
            <button onClick={handleSave} disabled={loading}>
                {loading ? 'Guardando...' : 'Guardar'}
            </button>
            {error && <p className="error-message">{error.message}</p>}
        </div>
    );
};

CalendarWithSchoolHolidays.propTypes = {
    idEscola: PropTypes.number,
};

export default CalendarWithSchoolHolidays;
