// // PropTypes & React HOOKS
// import PropTypes from 'prop-types';
// import React from 'react';
// import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter } from 'react-table';
// import { useNavigate } from 'react-router-dom';

// // COMPONENTS
// import FilterUp from 'components/icons/FilterUp';
// import FilterDown from 'components/icons/FilterDown';
// import SchoolsTableBar from './SchoolsTableBar';
// import { FaCalendarAlt } from 'react-icons/fa';

// export default function StudentsTable({ data, columns, handleClick }) {
//   const navigate = useNavigate();

//   const handleCalendarClick = (student) => {
//     navigate(`/calendar/${student.ID_ALUMNES}/${student.ID_ESCOLA}/${student.ID_PRACTIQUES}`);
//   };

//   // Inicializar la tabla:
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     nextPage,
//     previousPage,
//     canNextPage,
//     canPreviousPage,
//     pageOptions,
//     setPageSize,
//     state,
//     prepareRow
//   } = useTable(
//     { columns, data, initialState: { pageSize: 25 } },
//     useGlobalFilter,
//     useFilters,
//     useSortBy,
//     usePagination
//   );

//   const { pageIndex, pageSize } = state;

//   return (
//     <div className="flex flex-col">
//       <div className="align-middle inline-block min-w-full">
//         <table
//           className="table-auto min-w-full border-2 border-indigo-800"
//           {...getTableProps()}
//         >
//           <thead className="bg-indigo-900">
//             {headerGroups.map((headerGroup) => (
//               <tr
//                 className="divide-x divide-dashed divide-indigo-600"
//                 key={headerGroup.id}
//                 {...headerGroup.getHeaderGroupProps()}
//               >
//                 {headerGroup.headers.map((column) => (
//                   <th
//                     className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
//                     key={column.id}
//                     {...column.getHeaderProps(
//                       column.getSortByToggleProps({ title: undefined })
//                     )}
//                   >
//                     <div className="text-left text-sm ml-4">
//                       {column.render('Header')}
//                       {column.isSorted ? (
//                         column.isSortedDesc ? (
//                           <FilterDown
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         ) : (
//                           <FilterUp
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         )
//                       ) : ('')}
//                     </div>
//                     <div className="mx-4">
//                       {column.canFilter && column.render('Filter')}
//                     </div>
//                   </th>
//                 ))}
//                 <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
//                   <div className="text-left text-sm ml-4">Calendari</div>
//                 </th>
//               </tr>
//             ))}
//           </thead>
//           <tbody
//             style={{ borderColor: '#9CA3AF' }}
//             className="bg-white divide-y divide-gray-200"
//             {...getTableBodyProps()}
//           >
//             {page.map((row, index) => {
//               prepareRow(row);
//               return (
//                 <tr
//                   key={row.id}
//                   {...row.getRowProps()}
//                   onClick={(e) => handleClick && handleClick(e, row.original)}
//                   className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
//                     ${index % 2 === 0 && 'bg-indigo-50'}`}
//                 >
//                   <td className={`text-sm font-bold pl-4 py-4 w-1/16`}>
//                     {row.original.CODI_BECARI}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-2/16`}>
//                     {row.original.NOM}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-2/16`}>
//                     {row.original.COGNOM}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-2/16`}>
//                     {row.original.DNI}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-2/16`}>
//                     {row.original.DATA_NAIXAMENT}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-2/16`}>
//                     {row.original.NUM_SS}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-2/16`}>
//                     {row.original.Escola_Nom}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-3/16`}>
//                     {row.original.Professor_NombreCompleto}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-1/16 text-center`}>
//                     <button 
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleCalendarClick(row.original);
//                       }}
//                       className="text-indigo-800 hover:text-blue-700"
//                     >
//                       <FaCalendarAlt size={24} />
//                     </button>
//                   </td>
//                 </tr>
//               );
//             })}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td colSpan={columns.length + 1} className="p-0 m-0">
//                 <SchoolsTableBar
//                   previousPage={previousPage}
//                   canPreviousPage={canPreviousPage}
//                   pageIndex={pageIndex}
//                   pageOptions={pageOptions}
//                   nextPage={nextPage}
//                   canNextPage={canNextPage}
//                   pageSize={pageSize}
//                   setPageSize={setPageSize}
//                   datos={data}
//                 />
//               </td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// }

// StudentsTable.propTypes = {
//   columns: PropTypes.array.isRequired,
//   data: PropTypes.array.isRequired,
//   handleClick: PropTypes.func,
// };














// import PropTypes from 'prop-types';
// import React from 'react';
// import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter } from 'react-table';
// import { useNavigate } from 'react-router-dom';
// import dayjs from 'dayjs';

// // COMPONENTS
// import FilterUp from 'components/icons/FilterUp';
// import FilterDown from 'components/icons/FilterDown';
// import SchoolsTableBar from './SchoolsTableBar';
// import { FaCalendarAlt } from 'react-icons/fa';

// export default function StudentsTable({ data, columns, handleClick }) {
//   const navigate = useNavigate();

//   const handleCalendarClick = (student) => {
//     navigate(`/calendar/${student.ID_ALUMNES}/${student.ID_ESCOLA}/${student.ID_PRACTIQUES}`);
//   };

//   // Inicializar la tabla:
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     nextPage,
//     previousPage,
//     canNextPage,
//     canPreviousPage,
//     pageOptions,
//     setPageSize,
//     state,
//     prepareRow
//   } = useTable(
//     { columns, data, initialState: { pageSize: 25 } },
//     useGlobalFilter,
//     useFilters,
//     useSortBy,
//     usePagination
//   );

//   const { pageIndex, pageSize } = state;

//   return (
//     <div className="flex flex-col">
//       <div className="align-middle inline-block min-w-full">
//         <table
//           className="table-auto min-w-full border-2 border-indigo-800"
//           {...getTableProps()}
//         >
//           <thead className="bg-indigo-900">
//             {headerGroups.map((headerGroup) => (
//               <tr
//                 className="divide-x divide-dashed divide-indigo-600"
//                 key={headerGroup.id}
//                 {...headerGroup.getHeaderGroupProps()}
//               >
//                 {headerGroup.headers.map((column) => (
//                   <th
//                     className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
//                     key={column.id}
//                     {...column.getHeaderProps(
//                       column.getSortByToggleProps({ title: undefined })
//                     )}
//                   >
//                     <div className="text-left text-sm ml-4">
//                       {column.render('Header')}
//                       {column.isSorted ? (
//                         column.isSortedDesc ? (
//                           <FilterDown
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         ) : (
//                           <FilterUp
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         )
//                       ) : ('')}
//                     </div>
//                     <div className="mx-4">
//                       {column.canFilter && column.render('Filter')}
//                     </div>
//                   </th>
//                 ))}
//                 <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
//                   <div className="text-left text-sm ml-4">Calendari</div>
//                 </th>
//               </tr>
//             ))}
//           </thead>
//           <tbody
//             style={{ borderColor: '#9CA3AF' }}
//             className="bg-white divide-y divide-gray-200"
//             {...getTableBodyProps()}
//           >
//             {page.map((row, index) => {
//               prepareRow(row);
//               return (
//                 <tr
//                   key={row.id}
//                   {...row.getRowProps()}
//                   onClick={(e) => handleClick && handleClick(e, row.original)}
//                   className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
//                     ${index % 2 === 0 && 'bg-indigo-50'}`}
//                 >
//                   {row.cells.map((cell) => {
//                     const cellValue = cell.value;
//                     let formattedValue = cellValue;

//                     if (cell.column.id === 'DATA_INICI' || cell.column.id === 'DATA_FINALITZACIO') {
//                       formattedValue = dayjs(cellValue).format('DD/MM/YYYY');
//                     } else if (cell.column.id === 'CHECK_TASCA_INICI' || cell.column.id === 'CHECK_TASCA_FINAL') {
//                       formattedValue = cellValue ? '✔️' : '❌';
//                     }

//                     return (
//                       <td
//                         key={cell.column.id}
//                         className="text-sm font-medium pl-4 py-4"
//                         style={{ width: cell.column.width || '12.5%' }}
//                       >
//                         {formattedValue}
//                       </td>
//                     );
//                   })}
//                   <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
//                     <button 
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleCalendarClick(row.original);
//                       }}
//                       className="text-indigo-800 hover:text-blue-700"
//                     >
//                       <FaCalendarAlt size={24} />
//                     </button>
//                   </td>
//                 </tr>
//               );
//             })}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td colSpan={columns.length + 1} className="p-0 m-0">
//                 <SchoolsTableBar
//                   previousPage={previousPage}
//                   canPreviousPage={canPreviousPage}
//                   pageIndex={pageIndex}
//                   pageOptions={pageOptions}
//                   nextPage={nextPage}
//                   canNextPage={canNextPage}
//                   pageSize={pageSize}
//                   setPageSize={setPageSize}
//                   datos={data}
//                 />
//               </td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// }

// StudentsTable.propTypes = {
//   columns: PropTypes.array.isRequired,
//   data: PropTypes.array.isRequired,
//   handleClick: PropTypes.func,
// };





// import PropTypes from 'prop-types';
// import React from 'react';
// import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter } from 'react-table';
// import { useNavigate } from 'react-router-dom';

// // COMPONENTS
// import FilterUp from 'components/icons/FilterUp';
// import FilterDown from 'components/icons/FilterDown';
// import SchoolsTableBar from './SchoolsTableBar';
// import { FaCalendarAlt } from 'react-icons/fa';
// import dayjs from 'dayjs';

// export default function StudentsTable({ data, columns, handleClick }) {
//   const navigate = useNavigate();

//   const handleCalendarClick = (student) => {
//     navigate(`/calendar/${student.ID_ALUMNES}/${student.ID_ESCOLA}/${student.ID_PRACTIQUES}`);
//   };

//   // Inicializar la tabla:
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     nextPage,
//     previousPage,
//     canNextPage,
//     canPreviousPage,
//     pageOptions,
//     setPageSize,
//     state,
//     prepareRow
//   } = useTable(
//     { columns, data, initialState: { pageSize: 25 } },
//     useGlobalFilter,
//     useFilters,
//     useSortBy,
//     usePagination
//   );

//   const { pageIndex, pageSize } = state;

//   return (
//     <div className="flex flex-col">
//       <div className="align-middle inline-block min-w-full">
//         <table
//           className="table-auto min-w-full border-2 border-indigo-800"
//           {...getTableProps()}
//         >
//           <thead className="bg-indigo-900">
//             {headerGroups.map((headerGroup) => (
//               <tr
//                 className="divide-x divide-dashed divide-indigo-600"
//                 key={headerGroup.id}
//                 {...headerGroup.getHeaderGroupProps()}
//               >
//                 {headerGroup.headers.map((column) => (
//                   <th
//                     className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
//                     key={column.id}
//                     {...column.getHeaderProps(
//                       column.getSortByToggleProps({ title: undefined })
//                     )}
//                   >
//                     <div className="text-left text-sm ml-4">
//                       {column.render('Header')}
//                       {column.isSorted ? (
//                         column.isSortedDesc ? (
//                           <FilterDown
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         ) : (
//                           <FilterUp
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         )
//                       ) : ('')}
//                     </div>
//                     <div className="mx-4">
//                       {column.canFilter && column.render('Filter')}
//                     </div>
//                   </th>
//                 ))}
//                 <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
//                   <div className="text-left text-sm ml-4">Calendari</div>
//                 </th>
//               </tr>
//             ))}
//           </thead>
//           <tbody
//             style={{ borderColor: '#9CA3AF' }}
//             className="bg-white divide-y divide-gray-200"
//             {...getTableBodyProps()}
//           >
//             {page.map((row, index) => {
//               prepareRow(row);
//               return (
//                 <tr
//                   key={row.id}
//                   {...row.getRowProps()}
//                   onClick={(e) => handleClick && handleClick(e, row.original)}
//                   className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
//                     ${index % 2 === 0 && 'bg-indigo-50'}`}
//                 >
//                   {columns.map((column) => (
//                     <td
//                       key={column.id}
//                       className="text-sm font-medium pl-4 py-4"
//                       style={{ width: column.width || '12.5%' }}
//                     >
//                       {column.Cell ? column.Cell(row) : row.values[column.accessor]}
//                     </td>
//                   ))}
//                   <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
//                     <button 
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleCalendarClick(row.original);
//                       }}
//                       className="text-indigo-800 hover:text-blue-700"
//                     >
//                       <FaCalendarAlt size={24} />
//                     </button>
//                   </td>
//                 </tr>
//               );
//             })}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td colSpan={columns.length + 1} className="p-0 m-0">
//                 <SchoolsTableBar
//                   previousPage={previousPage}
//                   canPreviousPage={canPreviousPage}
//                   pageIndex={pageIndex}
//                   pageOptions={pageOptions}
//                   nextPage={nextPage}
//                   canNextPage={canNextPage}
//                   pageSize={pageSize}
//                   setPageSize={setPageSize}
//                   datos={data}
//                 />
//               </td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// }

// StudentsTable.propTypes = {
//   columns: PropTypes.array.isRequired,
//   data: PropTypes.array.isRequired,
//   handleClick: PropTypes.func,
// };



import PropTypes from 'prop-types';
import React from 'react';
import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import FilterUp from 'components/icons/FilterUp';
import FilterDown from 'components/icons/FilterDown';
import SchoolsTableBar from './SchoolsTableBar';
import StudentsTableBar from './StudentsTableBar';
import { FaCalendarAlt } from 'react-icons/fa';

export default function StudentsTable({ data, columns, handleClick, refreshData }) {
  const navigate = useNavigate();

  const handleCalendarClick = (student) => {
    navigate(`/calendar/${student.ID_ALUMNES}/${student.ID_ESCOLA}/${student.ID_PRACTIQUES}`);
  };

  // Inicializar la tabla:
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state,
    prepareRow
  } = useTable(
    { columns, data, initialState: { pageSize: 25 } },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize } = state;

  return (
    <div className="flex flex-col">
      <div className="align-middle inline-block min-w-full">
        <table
          className="table-auto min-w-full border-2 border-indigo-800"
          {...getTableProps()}
        >
          <thead className="bg-indigo-900">
            {headerGroups.map((headerGroup) => (
              <tr
                className="divide-x divide-dashed divide-indigo-600"
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
                    key={column.id}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ title: undefined })
                    )}
                  >
                    <div className="text-left text-sm ml-4">
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FilterDown
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        ) : (
                          <FilterUp
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        )
                      ) : ('')}
                    </div>
                    <div className="mx-4">
                      {column.canFilter && column.render('Filter')}
                    </div>
                  </th>
                ))}
                <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
                  <div className="text-left text-sm ml-4">Calendari</div>
                </th>
              </tr>
            ))}
          </thead>
          <tbody
            style={{ borderColor: '#9CA3AF' }}
            className="bg-white divide-y divide-gray-200"
            {...getTableBodyProps()}
          >
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  onClick={(e) => handleClick && handleClick(e, row.original)}
                  className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
                    ${index % 2 === 0 && 'bg-indigo-50'}`}
                >
                  {row.cells.map((cell) => (
                    <td
                      key={cell.column.id}
                      className="text-sm font-medium pl-4 py-4"
                      style={{ width: cell.column.width || '12.5%' }}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                  <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
                    <button 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCalendarClick(row.original);
                      }}
                      className="text-indigo-800 hover:text-blue-700"
                    >
                      <FaCalendarAlt size={24} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={columns.length + 1} className="p-0 m-0">
                <StudentsTableBar
                  previousPage={previousPage}
                  canPreviousPage={canPreviousPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  nextPage={nextPage}
                  canNextPage={canNextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  datos={data}
                  onSuccess={refreshData}
                  onSuccessPracts={refreshData}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

StudentsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  refreshData: PropTypes.func.isRequired, // Ensure this is here
};
