import PropTypes from 'prop-types'

import flecha from 'assets/img/imageInvert.png'

export default function MailsTableBar({
  previousPage,
  canPreviousPage,
  pageIndex,
  pageOptions,
  nextPage,
  canNextPage,
  pageSize,
  setPageSize,
  datos
}) {
  return (
    <div
      className={`text-center print:hidden border-t-2 border-blue-600 px-5 bg-white grid grid-cols-12
        ${pageOptions.length > 1 ? 'py-5' : 'py-6'}`}
    >
      {/* Recuento registros: */}
      <div className="col-span-4 justify-self-start self-center">
        <span>
          <strong>{datos.length.toLocaleString()}</strong> registros
        </span>
      </div>

      {/* Paginación: */}
      {pageOptions.length > 1 && (
        <div className="col-span-4 place-self-center">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="font-bold rounded-full bg-blue-700 text-white disabled:opacity-0"
            style={{ width: '24px', marginRight: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ transform: 'rotate(180deg)', margin: 'auto' }}
            />
          </button>

          <span>
            Página <strong>{(pageIndex + 1).toLocaleString()}</strong> de{' '}
            <strong>{pageOptions.length.toLocaleString()}</strong>
          </span>

          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="font-bold rounded-full bg-blue-700 text-white disabled:opacity-0"
            style={{ width: '24px', marginLeft: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ margin: 'auto' }}
            />
          </button>
        </div>
      )}

      {/* Cantidad de registros mostrados por página: */}
      {pageOptions.length > 1 && (
        <select
          className="col-span-4 float-right border-2 border-blue-700 bg-blue-50 rounded p-1 font-semibold outline-none justify-self-end self-center"
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[25, 75, 150, 250, 500].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize} registros
            </option>
          ))}
        </select>
      )}
    </div>
  )
}

MailsTableBar.propTypes = {
  previousPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  nextPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  datos: PropTypes.array
}
