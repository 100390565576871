import PropTypes from 'prop-types'
import { Link } from 'wouter'

// Images
import whiteLogo from 'assets/img/logo-codina-web.png'
import redLogo from 'assets/img/logo-codina-web-negativo-1.png'

export default function Logo({ classes, link, color }) {
  return (
    <Link href={link}>
      <a>
        <img
          className={classes}
          src={color === 'white' ? whiteLogo : redLogo}
          alt="Workflow"
        />
      </a>
    </Link>
  )
}
Logo.propTypes = {
  classes: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  color: PropTypes.string
}
