// PropTypes & HOOKS
import PropTypes from 'prop-types'

// COMPONENTS
import Navbar from './Navbar/Navbar'
import Title from './Title/Title'
import Sidebar from './Sidebar/Sidebar'
import Footer from './Footer/Footer'

export default function Layout({ children }) {
  return (
    <>
      <section className="w-full mx-auto bg-gray-100 flex flex-col print:m-0 print:p-0">
        {/* Barra navegación: */}
        <Navbar />

        <main className="flex h-full print:m-0 print:p-0">
          {/* Barra lateral: */}
          <Sidebar />

          {/* Cuerpo: */}
          <div className="flex-col w-full bg-white print:m-0 print:p-0">
            <Title />
            <div className="flex flex-col p-3 print:m-0 print:p-0">
              {children}
            </div>
          </div>
        </main>
        <Footer />
      </section>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}
