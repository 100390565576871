// PropTypes & React HOOKS
import PropTypes from 'prop-types'

// React Table HOOK
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useGlobalFilter
} from 'react-table'

// COMPONENTS
import FilterUp from 'components/icons/FilterUp'
import FilterDown from 'components/icons/FilterDown'

export default function AdminTable({ data, columns, handleClick }) {
  // Inicializar la tabla:
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable(
      { columns, data, initialState: { pageSize: 25 } },
      useGlobalFilter,
      useFilters,
      useSortBy,
      usePagination
    )

  return (
    <div className="flex flex-col">
      <div className="align-middle inline-block min-w-full">
        {/* Tabla: */}
        <table
          className="table-auto min-w-full border-2 border-indigo-800"
          {...getTableProps()}
        >
          {/* Cabecera: */}
          <thead className="bg-indigo-900">
            {headerGroups.map((headerGroup) => (
              <tr
                className="divide-x divide-dashed divide-indigo-600"
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
                    key={column.id}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ title: undefined })
                    )}
                  >
                    <div className="text-left text-sm ml-4">
                      {column.render('Header')}

                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FilterDown
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        ) : (
                          <FilterUp
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        )
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="mx-4">
                      {column.canFilter && column.render('Filter')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Cuerpo: */}
          <tbody
            style={{ borderColor: '#9CA3AF' }}
            className="bg-white divide-y divide-gray-200"
            {...getTableBodyProps()}
          >
            {page.map((row, index) => {
              prepareRow(row)
              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  onClick={(e) => handleClick && handleClick(e, row.original)}
                  className={`hover:bg-blue-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-blue-200
                    ${index % 2 === 0 && 'bg-blue-50'}`}
                >
                  <td className={`text-sm font-bold pl-4 py-4 w-1/2`}>
                    {page[index].original.Admin_UserName}
                  </td>

                  <td className={`text-sm font-bold pl-4 py-4 w-1/2`}>
                    {page[index].original.Admin_Name}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

AdminTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func
}
