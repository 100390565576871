// PropTypes & React HOOKS
import PropTypes from 'prop-types'

// React Table HOOK
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useGlobalFilter
} from 'react-table'

// COMPONENTS
import FilterUp from 'components/icons/FilterUp'
import FilterDown from 'components/icons/FilterDown'
import SinglesTableBar from './SinglesTableBar'

// ICONS
import { BsPersonFill, BsListCheck } from 'react-icons/bs'

export default function SinglesTable({ data, columns, handleClick }) {
  // Inicializar la tabla:
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state,
    prepareRow
  } = useTable(
    { columns, data, initialState: { pageSize: 25 } },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize } = state

  return (
    <div className="flex flex-col">
      <div className="align-middle inline-block min-w-full">
        {/* Tabla: */}
        <table
          className="table-auto min-w-full border-2 border-blue-600"
          {...getTableProps()}
        >
          {/* Cabecera */}
          <thead className="bg-blue-700">
            {headerGroups.map((headerGroup) => (
              <tr
                className="divide-x divide-dashed divide-blue-600"
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className="text-white py-2 hover:bg-blue-500 font-ms-semi"
                    key={column.id}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ title: undefined })
                    )}
                  >
                    <div className="text-left text-sm ml-4">
                      {column.render('Header')}

                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FilterDown
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        ) : (
                          <FilterUp
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        )
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="mx-4">
                      {column.canFilter && column.render('Filter')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Cuerpo: */}
          <tbody
            style={{ borderColor: '#9CA3AF' }}
            className="bg-white divide-y divide-gray-200"
            {...getTableBodyProps()}
          >
            {page.map((row, index) => {
              prepareRow(row)

              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  onClick={(e) => handleClick && handleClick(e, row.original)}
                  className={`hover:bg-blue-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-blue-200
                    ${index % 2 === 0 && 'bg-blue-50'}`}
                >
                  <td className={`text-sm font-bold px-4 py-4 w-2/12`}>
                    {page[index].original.Singles_DNI ? (
                      <div className="w-full">
                        <span
                          className="w-3/4 mx-auto grid grid-cols-3 bg-gray-500 border-2 border-gray-600 
                          text-white rounded py-0.5"
                          title="DNI de cliente"
                        >
                          <div className="w-full flex justify-center items-center col-span-1 border-r border-dashed border-gray-600">
                            <BsPersonFill size={32} />
                          </div>

                          <div className="w-full flex justify-center items-center col-span-2">
                            {page[index].original.Singles_DNI}
                          </div>
                        </span>
                      </div>
                    ) : null}
                  </td>

                  <td className={`text-sm font-bold py-4 w-1/12`}>
                    {page[index].original.Singles_IdInterno ? (
                      <div className="flex justify-center items-center">
                        <p
                          className="w-5/6 m-auto transition duration-300 
                          border-gray-600 bg-gray-500 text-white py-2 px-2 border-2 rounded 
                          flex justify-center items-center"
                          title="Número identificador de cliente"
                        >
                          {page[index].original.Singles_IdInterno}
                        </p>
                      </div>
                    ) : null}
                  </td>

                  <td className={`text-sm font-bold pl-4 py-4 w-3/12`}>
                    {page[index].original.Singles_Name}
                  </td>

                  <td className={`text-sm font-bold pl-4 py-4 w-3/12`}>
                    {page[index].original.Singles_Surnames}
                  </td>

                  <td className={`text-sm font-bold pl-4 py-4 w-3/12`}>
                    {!page[index].original.Singles_Email ? (
                      <div className="flex justify-center items-center">
                        <p
                          className="w-3/4 m-auto transition duration-300 
                          border-amber-600 bg-amber-500 text-white font-sm py-1 px-2 border-2 rounded font-bold
                          flex justify-center items-center"
                          title="Cliente sin correo."
                        >
                          SIN CORREO
                        </p>
                      </div>
                    ) : (
                      <span className="pl-4">
                        {page[index].original.Singles_Email}
                      </span>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>

          {/* Pie: */}
          <tfoot>
            <tr>
              <td colSpan={columns.length} className="p-0 m-0">
                <SinglesTableBar
                  previousPage={previousPage}
                  canPreviousPage={canPreviousPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  nextPage={nextPage}
                  canNextPage={canNextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  datos={data}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

SinglesTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func
}
