// PropTypes
import PropTypes from 'prop-types'
import { useLocation } from 'wouter'

// ICONS
import iconGraph from 'assets/icons/iconCouple.png'

const DashboardStudents = ({ fieldsDashStudents }) => {
    const [, setLocation] = useLocation()
  return (
    <div
      className="flex flex-col col-span-1 relative 
      bg-gray-100 border-2 border-gray-700 rounded
      font-ms-semi 
      w-full
      h-60 mx-5 mb-5"
    >
      <div className="h-full flex flex-col">
        <div className="flex flex-row justify-start items-center bg-gray-200 border-b-2 border-gray-700 h-auto">
          <div className="px-3">
            <img src={iconGraph} className="w-7" alt="icon" />
          </div>

          <h3 className="text-gray-600 text-2xl pt-2 pb-2 text border-blue-700 font-bold justify-self-center">
            Alumnes
          </h3>
        </div>

        <div className="flex justify-center mt-4">
            <button
                className="mx-4 w-1/3 p-2 bg-[#29235C] text-white text-lg font-bold rounded border-none hover:bg-[#66618A] hover:border-2 hover:border-[#29235C]"
                onClick={() => setLocation('/students/grid')}
            >
                Accedir
            </button>
        </div>

        <div className="w-full flex flex-grow flex-col px-2 justify-end">
          <hr className="text-gray-700 w-full" />
          <p className="text-base text-gray-600 py-4 font-semibold text-center">
            Total:
            <span className="text-2xl text border-blue-700">
              {''} &nbsp;&nbsp;
              {(fieldsDashStudents?.total).toLocaleString() ?? 'n.c.'}
            </span>{' '}
            Alumnes
          </p>
        </div>
      </div>
    </div>
  )
}

export default DashboardStudents

DashboardStudents.propTypes = {
  fieldsDashStudents: PropTypes.object
}
