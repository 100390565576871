// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useContext, useState, useEffect } from 'react'

// useLocation() + CONTEXTS
import { useLocation } from 'wouter'
import { ModalContext } from 'contexts/ModalContext'

// ICONS
import loadingIco from 'components/ui/Logo/loading_azul.gif'
// import loadingIco from 'assets/img/loading1.gif'

export default function LoadingModal({ params }) {
  // useLocation():
  const [, setLocation] = useLocation()

  // Estados
  const [getTimeoutId, setTimeoutId] = useState(null)

  /**
   * @param isModalOpen    - abrir modal
   * @param closeModal     - cerrar modal
   * @param isLoading      - estado 'loading' de la llamada de la API
   * @param url            - url a dirigir cuando se cierre el modal
   * @param message        - mensaje para mostrar en el modal
   * @param apiCallError   - error de la llamada de la API
   * @param apiCallMessage - mensaje de la llamada de la API
   * @param isSync         - true (sincronizar el abrir y cerrar del modal con el estado 'loading' de la API)
   *                         o false (poner un segundo de espera obligatorio)
   */
  const { isModalOpen, closeModal } = useContext(ModalContext)
  const { isLoading, url, message, apiCallError, apiCallMessage, isSync } =
    useContext(ModalContext).getModalData

  // Bloqueamos el scroll:
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  window.onscroll = function () {
    window.scrollTo(scrollLeft, scrollTop)
  }

  isSync
    ? useEffect(() => {
        if (!isLoading || apiCallError) {
          if (getTimeoutId !== null) {
            clearTimeout(getTimeoutId)
          }

          if (url !== null) {
            setLocation(url)
          }
          closeModal()
          window.onscroll = function () {}
        }
      }, [isLoading, apiCallError])
    : useEffect(() => {
        if (apiCallError) {
          if (getTimeoutId !== null) {
            clearTimeout(getTimeoutId)
          }

          if (url !== null) {
            setLocation(url)
          }
          closeModal()
          window.onscroll = function () {}
        } else {
          if (getTimeoutId === null) {
            const timeOutId = setTimeout(() => {
              if (url !== null) {
                setLocation(url)
              }
              closeModal()
              window.onscroll = function () {}
            }, 1 * 60 * 2500)

            setTimeoutId(timeOutId)
          }
        }

        return () => clearTimeout(getTimeoutId)
      }, [apiCallError])

  const clearTimer = () => {
    clearTimeout(getTimeoutId)
  }

  return (
    isModalOpen && (
      <>
        <div className="w-screen h-screen fixed bg-black bg-opacity-75 top-0 left-0 z-50 flex justify-center items-center" />

        <div
          id="staticModal"
          className="w-1/2 m-auto justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative w-full my-6 mx-auto max-w-6xl">
            <div
              className="w-full border-2 border-blue-600 rounded-md shadow-lg relative flex flex-col bg-white outline-none focus:outline-none
                divide-y divide-dashed divide-blue-200"
            >
              {/* CABECERA */}
              <div className="flex items-center justify-start p-5">
                <h3 className="text-2xl font-semibold">
                  <b>CARGANDO...</b>
                </h3>
              </div>

              {/* CUERPO */}
              <div className="w-full flex flex-col justify-center items-center py-12">
                <p className="w-full text-lg font-bold text-blue-700 flex flex-col justify-around items-center text-center px-1">
                  {message}

                  <div
                    className="mt-10"
                    style={{ width: '75px', height: '75px' }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 100 100"
                    >
                      <style>
                        {`
                          @keyframes zoomInOut {
                            0%, 100%, 5%, 95% {
                              transform: translate(50px, 50px) scale(0);
                            }
                            20%, 80% {
                              transform: translate(10px, 10px) scale(.8);
                            }
                          }
                          
                          @keyframes spin {
                            from {
                              transform: rotate(0);
                            }
                            to {
                              transform: rotate(360deg);
                            }
                          }
                        `}
                      </style>

                      <defs>
                        <mask id="Mask">
                          <circle cx="50" cy="50" r="50" fill="#fff"></circle>
                          <circle
                            cx="50"
                            cy="50"
                            r="50"
                            style={{
                              animationName: 'zoomInOut',
                              animationDuration: '5.2s',
                              animationIterationCount: 'infinite',
                              animationTimingFunction: 'linear'
                            }}
                          ></circle>
                        </mask>
                      </defs>

                      <g
                        style={{
                          animationName: 'spin',
                          animationDuration: '1.3s',
                          transformOrigin: 'center',
                          animationIterationCount: 'infinite',
                          animationTimingFunction: 'linear'
                        }}
                        fill="#1C69D4"
                        mask="url(#Mask)"
                      >
                        <path d="M0 0h50v50H0zM50 50h50v50H50z"></path>
                      </g>
                    </svg>
                  </div>
                </p>
              </div>

              {/* PIE */}
              <div className="flex items-center justify-end border-t border-solid border-slate-200 rounded-b p-3">
                <button
                  className={`transition duration-300 
                    ${
                      apiCallMessage
                        ? 'border-teal-600 bg-teal-500 text-white hover:border-teal-400 hover:bg-teal-300 hover:text-teal-600'
                        : 'border-teal-600 bg-teal-500 text-white opacity-50 cursor-not-allowed'
                    }
                    font-ms-semi py-2 px-6 border-2 rounded 
                    flex justify-center items-center font-bold`}
                  onClick={() => {
                    if (getTimeoutId !== null) {
                      clearTimer()
                    }

                    if (url !== null) {
                      setLocation(url)
                    }

                    closeModal()
                    window.onscroll = function () {}
                  }}
                  disabled={!apiCallMessage}
                >
                  ACEPTAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  )
}

LoadingModal.propTypes = {
  params: PropTypes.any
}
