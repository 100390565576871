// PropTypes
import React from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import LoginForm from 'forms/login/loginForm'

export default function LoginComp({ loading, error, handleSubmitLogin }) {
  return (
    <>
      <div className="w-full md:w-96 bg-white shadow-md rounded px-8 py-2 flex flex-col m-3 self-stretch">
        <div className="pt-3 text-center leading-6 pb-6 sm:text-2xl sm:leading-7">
          <h2>Accés Codina Assessors</h2>
        </div>

        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">
              ¡Usuario y contraseña incorrectos!
            </span>
          </div>
        )}

        <LoginForm loading={loading} handleSubmit={handleSubmitLogin} />
      </div>
    </>
  )
}

LoginComp.propTypes = {
  handleSubmitLogin: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool
}
