// import PropTypes from 'prop-types'
// import { useEffect, useState } from 'react'

// // COMPONENTS
// import StudentsTable from 'components/ui/Table/StudentsTable'
// import ColumnFilter from 'components/ui/Table/ColumnFilter'
// import ColumnFilterSelect from 'components/ui/Table/ColumnFilterSelect'

// export default function StudentsCompGrid({ items, handleClick }) {
//   const [getTableData, setTableData] = useState([])

//   const columns = [
//     {
//       Header: 'Codi Becari',
//       accessor: 'CODI_BECARI',
//       Filter: ColumnFilter,
//       Type: 'codi_becari',
//       Key: 'codi_becari'
//     },
//     {
//       Header: 'Nom',
//       accessor: 'NOM',
//       Filter: ColumnFilter,
//       Type: 'nom',
//       Key: 'nom'
//     },
//     {
//       Header: 'Cognom',
//       accessor: 'COGNOM',
//       Filter: ColumnFilter,
//       Type: 'cognom',
//       Key: 'cognom'
//     },
//     {
//       Header: 'DNI',
//       accessor: 'DNI',
//       Filter: ColumnFilter,
//       Type: 'dni',
//       Key: 'dni'
//     },
//     {
//       Header: 'Data Naixement',
//       accessor: 'DATA_NAIXAMENT',
//       Filter: ColumnFilter,
//       Type: 'data_naixement',
//       Key: 'data_naixement'
//     },
//     {
//       Header: 'Num SS',
//       accessor: 'NUM_SS',
//       Filter: ColumnFilter,
//       Type: 'num_ss',
//       Key: 'num_ss'
//     },
//     {
//       Header: 'Escola',
//       accessor: 'Escola_Nom',
//       Filter: ColumnFilter,
//       Type: 'escola',
//       Key: 'escola'
//     },
//     {
//       Header: 'Professor',
//       accessor: 'Professor_NombreCompleto',
//       Filter: ColumnFilter,
//       Type: 'professor',
//       Key: 'professor'
//     }
//   ]

//   useEffect(() => {
//     if (items) {
//       setTableData(items)
//     }
//   }, [items])

//   return (
//     <div className="overflow-x-auto shadow-md print:shadow-none">
//       {getTableData.length > 0 && (
//         <StudentsTable
//           data={getTableData}
//           columns={columns}
//           handleClick={handleClick}
//         />
//       )}
//     </div>
//   )
// }

// StudentsCompGrid.propTypes = {
//   items: PropTypes.array.isRequired,
//   handleClick: PropTypes.func,
//   value: PropTypes.any
// }




// import PropTypes from 'prop-types';
// import { useContext, useEffect, useState } from 'react';
// import useFetch from 'hooks/useFetch';
// import { UserContext } from 'contexts/UserContext';

// // COMPONENTS
// import StudentsTable from 'components/ui/Table/StudentsTable';
// import ColumnFilter from 'components/ui/Table/ColumnFilter';

// export default function StudentsCompGrid({ handleClick }) {
//   const { user } = useContext(UserContext);
//   const [getApiCall, setApiCall] = useFetch();
//   const [getTableData, setTableData] = useState([]);

//   const columnsRole1 = [
//     {
//       Header: 'Codi Becari',
//       accessor: 'CODI_BECARI',
//       Filter: ColumnFilter,
//       Type: 'codi_becari',
//       Key: 'codi_becari'
//     },
//     {
//       Header: 'DNI',
//       accessor: 'DNI',
//       Filter: ColumnFilter,
//       Type: 'dni',
//       Key: 'dni'
//     },
//     {
//       Header: 'Cognom, Nom',
//       accessor: (row) => `${row.COGNOM} ${row.NOM}`,
//       Filter: ColumnFilter,
//       Type: 'cognom_nom',
//       Key: 'cognom_nom'
//     },
//     {
//       Header: 'Data Naixement',
//       accessor: 'DATA_NAIXAMENT',
//       Filter: ColumnFilter,
//       Type: 'data_naixement',
//       Key: 'data_naixement'
//     },
//     {
//       Header: 'Inici Pràctiques',
//       accessor: 'INICI_PRACTIQUES',
//       Filter: ColumnFilter,
//       Type: 'inici_practiques',
//       Key: 'inici_practiques'
//     },
//     {
//       Header: 'Fi Pràctiques',
//       accessor: 'FI_PRACTIQUES',
//       Filter: ColumnFilter,
//       Type: 'fi_practiques',
//       Key: 'fi_practiques'
//     }
//   ];

//   const columnsRole2 = [
//     {
//       Header: 'Codi Centre',
//       accessor: 'CODI_CENTRE',
//       Filter: ColumnFilter,
//       Type: 'codi_centre',
//       Key: 'codi_centre'
//     },
//     {
//       Header: 'Codi Becari',
//       accessor: 'CODI_BECARI',
//       Filter: ColumnFilter,
//       Type: 'codi_becari',
//       Key: 'codi_becari'
//     },
//     {
//       Header: 'DNI/NIE/PASSAPORT',
//       accessor: 'DNI_NIE_PASSAPORT',
//       Filter: ColumnFilter,
//       Type: 'dni',
//       Key: 'dni'
//     },
//     {
//       Header: 'Cognom, Nom',
//       accessor: 'COGNOMS_NOM',
//       Filter: ColumnFilter,
//       Type: 'cognom_nom',
//       Key: 'cognom_nom'
//     },
//     {
//       Header: 'Data Inici',
//       accessor: 'DATA_INICI',
//       Filter: ColumnFilter,
//       Type: 'data_inici',
//       Key: 'data_inici'
//     },
//     {
//       Header: 'Check Tasca Inici',
//       accessor: 'CHECK_TASCA_INICI',
//       Filter: ColumnFilter,
//       Type: 'check_tasca_inici',
//       Key: 'check_tasca_inici'
//     },
//     {
//       Header: 'Data Finalització',
//       accessor: 'DATA_FINALITZACIO',
//       Filter: ColumnFilter,
//       Type: 'data_finalitzacio',
//       Key: 'data_finalitzacio'
//     },
//     {
//       Header: 'Check Tasca Final',
//       accessor: 'CHECK_TASCA_FINAL',
//       Filter: ColumnFilter,
//       Type: 'check_tasca_final',
//       Key: 'check_tasca_final'
//     }
//   ];

//   useEffect(() => {
//     const fetchStudents = async () => {
//       let apiCall;
//       if (user.role_id === 1) {
//         apiCall = {
//           url: `students/bytchr/${user.id}`,
//           method: 'GET',
//           messageKo: 'Error al carregar els alumnes del professor'
//         };
//       } else if (user.role_id === 2) {
//         apiCall = {
//           url: 'students',
//           method: 'GET',
//           messageKo: 'Error al carregar els alumnes'
//         };
//       }

//       setApiCall(apiCall);
//     };

//     fetchStudents();
//   }, [user, setApiCall]);

//   useEffect(() => {
//     if (getApiCall.data) {
//       console.log(getApiCall.data); // Verifica los datos recibidos
//       setTableData(getApiCall.data);
//     }
//   }, [getApiCall.data]);

//   const columns = user.role_id === 1 ? columnsRole1 : columnsRole2;

//   return (
//     <div className="overflow-x-auto shadow-md print:shadow-none">
//       {getTableData.length > 0 && (
//         <StudentsTable
//           data={getTableData}
//           columns={columns}
//           handleClick={handleClick}
//         />
//       )}
//     </div>
//   );
// }

// StudentsCompGrid.propTypes = {
//   handleClick: PropTypes.func,
// };

// import PropTypes from 'prop-types';
// import { useContext, useEffect, useState } from 'react';
// import useFetch from 'hooks/useFetch';
// import { UserContext } from 'contexts/UserContext';
// // import CheckedIcon from 'assets/icons/checked-ok-mark-accept-check-approved-svgrepo-com.svg';
// // import ErrorIcon from 'assets/icons/error-icon.svg'; // Asegúrate de que la ruta sea correcta
// import { format } from 'date-fns'; // Importa format de date-fns
// // COMPONENTS
// import StudentsTable from 'components/ui/Table/StudentsTable';
// import ColumnFilter from 'components/ui/Table/ColumnFilter';
// import CheckCell from './CheckCell'; // Importa CheckCell

// export default function StudentsCompGrid({ handleClick }) {
//   const { user } = useContext(UserContext);
//   const [getApiCall, setApiCall] = useFetch();
//   const [getTableData, setTableData] = useState([]);

//   const formatDate = (date) => {
//     return date ? format(new Date(date), 'dd-MM-yyyy') : '';
//   };

//   const columnsRole1 = [
//     {
//       Header: 'Codi Becari',
//       accessor: 'CODI_BECARI',
//       Filter: ColumnFilter,
//       Type: 'codi_becari',
//       Key: 'codi_becari'
//     },
//     {
//       Header: 'DNI',
//       accessor: 'DNI',
//       Filter: ColumnFilter,
//       Type: 'dni',
//       Key: 'dni'
//     },
//     {
//       Header: 'Cognom, Nom',
//       accessor: (row) => `${row.COGNOM} ${row.NOM}`,
//       Filter: ColumnFilter,
//       Type: 'cognom_nom',
//       Key: 'cognom_nom'
//     },
//     {
//       Header: 'Num SS',
//       accessor: 'NUM_SS',
//       Filter: ColumnFilter,
//       Type: 'num_ss',
//       Key: 'num_ss'
//     },
//     {
//       Header: 'Data Naixement',
//       accessor: (row) => formatDate(row.DATA_NAIXAMENT),
//       Filter: ColumnFilter,
//       Type: 'data_naixement',
//       Key: 'data_naixement'
//     },
//     {
//       Header: 'Data Inici',
//       accessor: (row) => formatDate(row.DATA_INICI),
//       Filter: ColumnFilter,
//       Type: 'data_inici',
//       Key: 'data_inici'
//     },
//     {
//       Header: 'Data Finalització',
//       accessor: (row) => formatDate(row.DATA_FINALITZACIO),
//       Filter: ColumnFilter,
//       Type: 'data_finalitzacio',
//       Key: 'data_finalitzacio'
//     },
//   ];

//   const columnsRole2 = [
//     {
//       Header: 'Codi Centre',
//       accessor: 'CODI_CENTRE',
//       Filter: ColumnFilter,
//       Type: 'codi_centre',
//       Key: 'codi_centre'
//     },
//     {
//       Header: 'Codi Becari',
//       accessor: 'CODI_BECARI',
//       Filter: ColumnFilter,
//       Type: 'codi_becari',
//       Key: 'codi_becari'
//     },
//     {
//       Header: 'DNI/NIE/PASSAPORT',
//       accessor: 'DNI_NIE_PASSAPORT',
//       Filter: ColumnFilter,
//       Type: 'dni',
//       Key: 'dni'
//     },
//     {
//       Header: 'Cognom, Nom',
//       accessor: 'COGNOMS_NOM',
//       Filter: ColumnFilter,
//       Type: 'cognom_nom',
//       Key: 'cognom_nom'
//     },
//     {
//       Header: 'Data Inici',
//       accessor: (row) => formatDate(row.DATA_INICI),
//       Filter: ColumnFilter,
//       Type: 'data_inici',
//       Key: 'data_inici'
//     },
//     {
//       Header: 'Check Tasca Inici',
//       accessor: 'CHECK_TASCA_INICI',
//       Filter: ColumnFilter,
//       Type: 'check_tasca_inici',
//       Key: 'check_tasca_inici',
//       Cell: CheckCell
//     },
//     {
//       Header: 'Data Finalització',
//       accessor: (row) => formatDate(row.DATA_FINALITZACIO),
//       Filter: ColumnFilter,
//       Type: 'data_finalitzacio',
//       Key: 'data_finalitzacio'
//     },
//     {
//       Header: 'Check Tasca Final',
//       accessor: 'CHECK_TASCA_FINAL',
//       Filter: ColumnFilter,
//       Type: 'check_tasca_final',
//       Key: 'check_tasca_final',
//       Cell: CheckCell
//     }
//   ];

//   useEffect(() => {
//     const fetchStudents = async () => {
//       let apiCall;
//       if (user.role_id === 1) {
//         apiCall = {
//           url: `students/bytchr/${user.id}`,
//           method: 'GET',
//           messageKo: 'Error al carregar els alumnes del professor'
//         };
//       } else if (user.role_id === 2) {
//         apiCall = {
//           url: 'students',
//           method: 'GET',
//           messageKo: 'Error al carregar els alumnes'
//         };
//       }

//       setApiCall(apiCall);
//     };

//     fetchStudents();
//   }, [user, setApiCall]);

//   useEffect(() => {
//     if (getApiCall.data) {
//       console.log(getApiCall.data); // Verifica los datos recibidos
//       setTableData(getApiCall.data);
//     }
//   }, [getApiCall.data]);

//   const columns = user.role_id === 1 ? columnsRole1 : columnsRole2;

//   return (
//     <div className="overflow-x-auto shadow-md print:shadow-none">
//       {getTableData.length > 0 && (
//         <StudentsTable
//           data={getTableData}
//           columns={columns}
//           handleClick={handleClick}
//         />
//       )}
//     </div>
//   );
// }

// StudentsCompGrid.propTypes = {
//   handleClick: PropTypes.func,
// };



import PropTypes from 'prop-types';
import { useContext, useEffect, useState, useCallback } from 'react';
import useFetch from 'hooks/useFetch';
import { UserContext } from 'contexts/UserContext';
import { format } from 'date-fns';
import StudentsTable from 'components/ui/Table/StudentsTable';
import ColumnFilter from 'components/ui/Table/ColumnFilter';
import CheckCell from './CheckCell';
import StudentsTableBar from 'components/ui/Table/StudentsTableBar';

export default function StudentsCompGrid({ handleClick }) {
  const { user } = useContext(UserContext);
  const [getApiCall, setApiCall] = useFetch();
  const [getTableData, setTableData] = useState([]);

  const formatDate = (date) => {
    return date ? format(new Date(date), 'dd-MM-yyyy') : '';
  };

  const columnsRole1 = [
    {
      Header: 'Codi Becari',
      accessor: 'CODI_BECARI',
      Filter: ColumnFilter,
      Type: 'codi_becari',
      Key: 'codi_becari'
    },
    {
      Header: 'DNI',
      accessor: 'DNI',
      Filter: ColumnFilter,
      Type: 'dni',
      Key: 'dni'
    },
    {
      Header: 'Cognom, Nom',
      accessor: (row) => `${row.COGNOM} ${row.NOM}`,
      Filter: ColumnFilter,
      Type: 'cognom_nom',
      Key: 'cognom_nom'
    },
    {
      Header: 'Num SS',
      accessor: 'NUM_SS',
      Filter: ColumnFilter,
      Type: 'num_ss',
      Key: 'num_ss'
    },
    {
      Header: 'Data Naixement',
      accessor: (row) => formatDate(row.DATA_NAIXAMENT),
      Filter: ColumnFilter,
      Type: 'data_naixement',
      Key: 'data_naixement'
    },
    {
      Header: 'Data Inici',
      accessor: (row) => formatDate(row.DATA_INICI),
      Filter: ColumnFilter,
      Type: 'data_inici',
      Key: 'data_inici'
    },
    {
      Header: 'Data Finalització',
      accessor: (row) => formatDate(row.DATA_FINALITZACIO),
      Filter: ColumnFilter,
      Type: 'data_finalitzacio',
      Key: 'data_finalitzacio'
    },
  ];

  const columnsRole2 = [
    {
      Header: 'Codi Centre',
      accessor: 'CODI_CENTRE',
      Filter: ColumnFilter,
      Type: 'codi_centre',
      Key: 'codi_centre'
    },
    {
      Header: 'Codi Becari',
      accessor: 'CODI_BECARI',
      Filter: ColumnFilter,
      Type: 'codi_becari',
      Key: 'codi_becari'
    },
    {
      Header: 'DNI/NIE/PASSAPORT',
      accessor: 'DNI_NIE_PASSAPORT',
      Filter: ColumnFilter,
      Type: 'dni',
      Key: 'dni'
    },
    {
      Header: 'Cognom, Nom',
      accessor: 'COGNOMS_NOM',
      Filter: ColumnFilter,
      Type: 'cognom_nom',
      Key: 'cognom_nom'
    },
    {
      Header: 'Data Inici',
      accessor: (row) => formatDate(row.DATA_INICI),
      Filter: ColumnFilter,
      Type: 'data_inici',
      Key: 'data_inici'
    },
    {
      Header: 'Check Tasca Inici',
      accessor: 'CHECK_TASCA_INICI',
      Filter: ColumnFilter,
      Type: 'check_tasca_inici',
      Key: 'check_tasca_inici',
      Cell: CheckCell
    },
    {
      Header: 'Data Finalització',
      accessor: (row) => formatDate(row.DATA_FINALITZACIO),
      Filter: ColumnFilter,
      Type: 'data_finalitzacio',
      Key: 'data_finalitzacio'
    },
    {
      Header: 'Check Tasca Final',
      accessor: 'CHECK_TASCA_FINAL',
      Filter: ColumnFilter,
      Type: 'check_tasca_final',
      Key: 'check_tasca_final',
      Cell: CheckCell
    }
  ];

   const fetchStudents = useCallback(() => {
    let apiCall;
    if (user.role_id === 1) {
      apiCall = {
        url: `students/bytchr/${user.id}`,
        method: 'GET',
        messageKo: 'Error al carregar els alumnes del professor'
      };
    } else if (user.role_id === 2) {
      apiCall = {
        url: 'students',
        method: 'GET',
        messageKo: 'Error al carregar els alumnes'
      };
    }

    setApiCall(apiCall);
  }, [user, setApiCall]);

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  useEffect(() => {
    if (getApiCall.data) {
      console.log(getApiCall.data); // Verifica los datos recibidos
      setTableData(getApiCall.data);
    }
  }, [getApiCall.data]);

  const columns = user.role_id === 1 ? columnsRole1 : columnsRole2;

  return (
    <div className="overflow-x-auto shadow-md print:shadow-none">
      <StudentsTable
        data={getTableData}
        columns={columns}
        handleClick={handleClick}
        refreshData={fetchStudents} // Pass refreshData function to StudentsTable
      />
    </div>
  );
}

StudentsCompGrid.propTypes = {
  handleClick: PropTypes.func,
};

//   useEffect(() => {
//     const fetchStudents = async () => {
//       let apiCall;
//       if (user.role_id === 1) {
//         apiCall = {
//           url: `students/bytchr/${user.id}`,
//           method: 'GET',
//           messageKo: 'Error al carregar els alumnes del professor'
//         };
//       } else if (user.role_id === 2) {
//         apiCall = {
//           url: 'students',
//           method: 'GET',
//           messageKo: 'Error al carregar els alumnes'
//         };
//       }

//       setApiCall(apiCall);
//     };

//     fetchStudents();
//   }, [user, setApiCall]);

//   useEffect(() => {
//     if (getApiCall.data) {
//       console.log(getApiCall.data); // Verifica los datos recibidos
//       setTableData(getApiCall.data);
//     }
//   }, [getApiCall.data]);

//   const columns = user.role_id === 1 ? columnsRole1 : columnsRole2;

//   return (
//     <div className="overflow-x-auto shadow-md print:shadow-none">
//       <StudentsTable
//         data={getTableData}
//         columns={columns}
//         handleClick={handleClick}
//       />
//     </div>
//   );
// }

// StudentsCompGrid.propTypes = {
//   handleClick: PropTypes.func,
// };
