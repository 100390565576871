// PropTypes & React HOOKS
import PropTypes from 'prop-types'
import { useContext } from 'react'

// React Table HOOK
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useGlobalFilter
} from 'react-table'

// COMPONENTS
import FilterUp from 'components/icons/FilterUp'
import FilterDown from 'components/icons/FilterDown'
import MailsTableBar from './MailsTableBar'
import { ModalContext } from 'contexts/ModalContext'

// ICONS
import { BsTrash } from 'react-icons/bs'

export default function MailsTable({
  data,
  columns,
  handleClick,
  handleDelete
}) {
  // Inicializar la tabla:
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state,
    prepareRow
  } = useTable(
    { columns, data, initialState: { pageSize: 25 } },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize } = state
  const { openModal } = useContext(ModalContext)

  const handleDeleteModal = (id) => {
    const modalData = {
      id,
      handleDelete,
      type: 'DELETE'
    }

    openModal(modalData)
  }

  return (
    <div className="flex flex-col">
      <div className="align-middle inline-block min-w-full">
        {/* Tabla: */}
        <table
          className="table-auto min-w-full border-2 border-blue-600"
          {...getTableProps()}
        >
          {/* Cabecera: */}
          <thead className="bg-blue-700">
            {headerGroups.map((headerGroup) => (
              <tr
                className="divide-x divide-dashed divide-blue-600"
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => {
                  return column.render('Header') !== 'Eliminar' ? (
                    <th
                      className="text-white py-2 hover:bg-blue-500 font-ms-semi"
                      key={column.id}
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({ title: undefined })
                      )}
                    >
                      <div
                        className={`text-left text-sm 
                        ${
                          column.render('Header') === 'Enviado'
                            ? 'flex justify-center items-center'
                            : 'ml-4'
                        }`}
                      >
                        {column.render('Header')}

                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FilterDown
                              width={10}
                              height={10}
                              className="inline-block ml-1 -mt-1"
                              fill="black"
                            />
                          ) : (
                            <FilterUp
                              width={10}
                              height={10}
                              className="inline-block ml-1 -mt-1"
                              fill="black"
                            />
                          )
                        ) : (
                          ''
                        )}
                      </div>

                      <div className="mx-4">
                        {column.canFilter && column.render('Filter')}
                      </div>
                    </th>
                  ) : (
                    <th
                      className="text-white py-2 font-ms-semi"
                      key={column.id}
                    >
                      <div className="flex justify-center items-center text-left text-sm">
                        {column.render('Header')}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          {/* Cuerpo: */}
          <tbody
            style={{ borderColor: '#9CA3AF' }}
            className="bg-white divide-y divide-gray-200"
            {...getTableBodyProps()}
          >
            {page.map((row, index) => {
              prepareRow(row)

              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  className={`hover:bg-blue-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-blue-200
                  ${index % 2 === 0 && 'bg-blue-50'} `}
                >
                  <td
                    className={`text-sm pl-4 py-4 w-1/12`}
                    onClick={(e) => handleClick && handleClick(e, row.original)}
                  >
                    {page[index].original.Mails_Id}
                  </td>

                  <td
                    className={`text-sm pl-4 py-4 w-1/12`}
                    onClick={(e) => handleClick && handleClick(e, row.original)}
                  >
                    {page[index].original.Mails_DateMail}
                  </td>

                  <td
                    className={`text-sm pl-4 py-4 w-4/12`}
                    onClick={(e) => handleClick && handleClick(e, row.original)}
                  >
                    {page[index].original.Mails_Subject}
                  </td>

                  <td
                    className={`text-sm pl-4 py-4 w-5/12`}
                    onClick={(e) => handleClick && handleClick(e, row.original)}
                  >
                    {page[index].original.Mails_Message}
                  </td>

                  <td className={`text-sm py-4 w-1/12`}>
                    <button
                      className="w-1/2 m-auto transition duration-300 
                        font-ms-semi py-1 px-2 border-2 rounded 
                        flex justify-center items-center border-red-600 bg-red-500 
                        text-white hover:border-red-400 hover:bg-red-300 hover:text-red-600"
                      type="button"
                      onClick={() => {
                        handleDeleteModal(page[index].original.Mails_Id)
                      }}
                    >
                      <BsTrash size={30} title="Eliminar" />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>

          {/* Pie: */}
          <tfoot>
            <tr>
              <td colSpan={columns.length} className="p-0 m-0">
                <MailsTableBar
                  previousPage={previousPage}
                  canPreviousPage={canPreviousPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  nextPage={nextPage}
                  canNextPage={canNextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  datos={data}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

MailsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  handleDelete: PropTypes.func
}
