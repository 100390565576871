// import PropTypes from 'prop-types'
// import { useContext, useEffect, useState } from 'react'
// import useFetch from 'hooks/useFetch'

// // COMPONENTS
// import StudentsFormDetails from 'forms/Students/StudentsFormDetails';
// import { toast } from 'react-toastify'

// // CONTEXTS
// import { useLocation } from 'wouter'
// import { TitleContext } from 'contexts/TitleContext'

// // ASSETS
// import { ImArrowLeft2 } from 'react-icons/im'
// import loadingIco from 'components/ui/Logo/loading_azul.gif'

// export default function StudentsViewDetails({ params }) {
//   // Contextos:
//   const [, setLocation] = useLocation()
//   const { setTitle } = useContext(TitleContext)

//   // useFetch():
//   const [getApiCallGet, setApiCallGet] = useFetch()
//   const [getApiCallPost, setApiCallPost] = useFetch()
//   const [getApiCallDelete, setApiCallDelete] = useFetch()

//   // Variables:
//   const isNew = params.id === 'new'

//   // Estado para los campos del formulario
//   const [fields, setFields] = useState({
//     ID_ALUMNES: 0,
//     CODI_BECARI: '',
//     NOM: '',
//     COGNOM: '',
//     DNI: '',
//     DATA_NAIXAMENT: '',
//     NUM_SS: '',
//     ID_ESCOLA: '',
//     ID_PROFESSOR: '' // Añadir campo para el profesor
//   })

//   // CRUD: GET
//   useEffect(() => {
//     const titleEdit = {
//       name: `Modificar Alumne`,
//       buttons: [
//         {
//           id: 'btnBack',
//           name: (
//             <span className="flex flex-row items-center w-full">
//               <span className="pl-3 pr-1">
//                 <ImArrowLeft2 size={20} />
//               </span>
//               <span className="pl-1 pr-3">Tornar</span>
//             </span>
//           ),
//           link: `/students/grid`
//         }
//       ],
//       deleteMTBtn: true,
//       mtId: params.id
//     }
  
//     const titleCreate = {
//       name: `Registrar Alumne`,
//       buttons: [
//         {
//           id: 'btnBack',
//           name: (
//             <span className="flex flex-row items-center w-full">
//               <span className="pl-3 pr-1">
//                 <ImArrowLeft2 size={20} />
//               </span>
//               <span className="pl-1 pr-3">Tornar</span>
//             </span>
//           ),
//           link: `/students/grid`
//         }
//       ]
//     }

//     if (!isNew) {
//       const getApiCall = {
//         url: `students/${params.id}`,
//         method: 'GET',
//         successMessage: null,
//         failureMessage: 'Error de càrrega!'
//       }
//       setApiCallGet(getApiCall)

//       setTitle(titleEdit)
//     } else {
//       setTitle(titleCreate)
//     }
//   }, [params.id, isNew, setApiCallGet, setTitle])

//   // * Recoger datos API: GET
//   useEffect(() => {
//     if (getApiCallGet.data) {
//       setFields({
//         ID_ALUMNES: getApiCallGet.data.ID_ALUMNES,
//         CODI_BECARI: getApiCallGet.data.CODI_BECARI,
//         NOM: getApiCallGet.data.NOM,
//         COGNOM: getApiCallGet.data.COGNOM,
//         DNI: getApiCallGet.data.DNI,
//         DATA_NAIXAMENT: getApiCallGet.data.DATA_NAIXAMENT,
//         NUM_SS: getApiCallGet.data.NUM_SS,
//         ID_ESCOLA: getApiCallGet.data.ID_ESCOLA,
//         ID_PROFESSOR: getApiCallGet.data.ID_PROFESSOR // Añadir campo para el profesor
//       })
//     }
//   }, [getApiCallGet.data])

//   // CRUD: POST / PUT
//   const handleSubmit = async (values) => {
//     let url = `students`
//     let method = 'POST'

//     if (!isNew) {
//       method = 'PUT'
//       url += `/${params.id}`
//     }

//     const apiCallPost = {
//       url,
//       method,
//       body: values,
//       messageOk: 'Dades guardades correctament',
//       messageKo: 'Error al guardar les dades',
//       redirectTo: '/students/grid'
//     }
//     await setApiCallPost(apiCallPost)
//   }

//   // * Mostrar mensaje API: POST / PUT
//   // ! Mostrar error API: POST / PUT
//   useEffect(() => {
//     if (getApiCallPost.isCalled) {
//       const successMessage = (message) =>
//         toast.success(message, {
//           position: toast.POSITION.TOP_CENTER
//         })

//       const errorMessage = (error) =>
//         toast.error(error, {
//           position: toast.POSITION.TOP_CENTER
//         })

//       if (getApiCallPost.message) {
//         successMessage(getApiCallPost.message)
//         setLocation(`/students/grid`)
//       }

//       if (getApiCallPost.error) {
//         errorMessage(getApiCallPost.error)
//         setLocation(`/students/grid`)
//       }
//     }
//   }, [getApiCallPost, setLocation])

//   // CRUD: DELETE
//   const handleDelete = async () => {
//     const apiCallDelete = {
//       url: `students/${params.id}`,
//       method: 'DELETE',
//       messageOk: 'Dades borrades correctament',
//       messageKo: 'Error al borrar les dades',
//       redirectTo: '/students/grid'
//     }
//     await setApiCallDelete(apiCallDelete)
//   }

//   // * Mostrar mensaje API: DELETE
//   // ! Mostrar error API: DELETE
//   useEffect(() => {
//     if (getApiCallDelete.isCalled) {
//       const successMessage = (message) =>
//         toast.success(message, {
//           position: toast.POSITION.TOP_CENTER
//         })

//       const errorMessage = (error) =>
//         toast.error(error, {
//           position: toast.POSITION.TOP_CENTER
//         })

//       if (getApiCallDelete.message) {
//         successMessage(getApiCallDelete.message)
//         setLocation(`/students/grid`)
//       }

//       if (getApiCallDelete.error) {
//         errorMessage(getApiCallDelete.error)
//         setLocation(`/students/grid`)
//       }
//     }
//   }, [getApiCallDelete, setLocation])

//   return isNew || fields.ID_ALUMNES !== 0 ? (
//     <StudentsFormDetails
//       handleSubmit={handleSubmit}
//       handleDelete={handleDelete}
//       fields={fields}
//       isNew={isNew}
//     />
//   ) : (
//     <div>
//       <div className="flex items-center content-center justify-center w-full h-full">
//         <p
//           className="flex flex-col-reverse items-center justify-center h-full font-extrabold text-green-700"
//           title="Carregant..."
//         >
//           Carregant!
//           <img src={loadingIco} alt="Loading..." />
//         </p>
//       </div>
//     </div>
//   )
// }

// StudentsViewDetails.propTypes = {
//   params: PropTypes.any
// }


// import PropTypes from 'prop-types';
// import { useContext, useEffect, useState } from 'react';
// import useFetch from 'hooks/useFetch';

// // COMPONENTS
// import StudentsFormDetails from 'forms/Students/StudentsFormDetails';
// import StudentsFormDetailByTeacher from 'forms/Students/StudentsFormDetailsByTeacher';
// import { toast } from 'react-toastify';

// // CONTEXTS
// import { useLocation } from 'wouter';
// import { TitleContext } from 'contexts/TitleContext';
// import { UserContext } from 'contexts/UserContext';

// // ASSETS
// import { ImArrowLeft2 } from 'react-icons/im';
// import loadingIco from 'components/ui/Logo/loading_azul.gif';

// export default function StudentsViewDetails({ params }) {
//   // Contextos:
//   const { user } = useContext(UserContext);
//   const [, setLocation] = useLocation();
//   const { setTitle } = useContext(TitleContext);

//   // useFetch():
//   const [getApiCallGet, setApiCallGet] = useFetch();
//   const [getApiCallPost, setApiCallPost] = useFetch();
//   const [getApiCallDelete, setApiCallDelete] = useFetch();

//   // Variables:
//   const isNew = params.id === 'new';

//   // Estado para los campos del formulario
//   const [fields, setFields] = useState({
//     // ID_ALUMNES: 0,
//     CODI_BECARI: '',
//     NOM: '',
//     COGNOM: '',
//     DNI: '',
//     DATA_NAIXAMENT: '',
//     NUM_SS: '',
//     ID_ESCOLA: '',
//     ID_PROFESSOR: '',
//     INICI_PRACTIQUES: '', // Añadir campo para el inicio de prácticas
//     FI_PRACTIQUES: '', // Añadir campo para el fin de prácticas
//     INICI_CHECK: 0,
//     FI_CHECK: 0,
//   });

//   // CRUD: GET
//   useEffect(() => {
//     const titleEdit = {
//       name: `Modificar Alumne`,
//       buttons: [
//         {
//           id: 'btnBack',
//           name: (
//             <span className="flex flex-row items-center w-full">
//               <span className="pl-3 pr-1">
//                 <ImArrowLeft2 size={20} />
//               </span>
//               <span className="pl-1 pr-3">Tornar</span>
//             </span>
//           ),
//           link: `/students/grid`,
//         },
//       ],
//       deleteMTBtn: true,
//       mtId: params.id,
//     };

//     const titleCreate = {
//       name: `Registrar Alumne`,
//       buttons: [
//         {
//           id: 'btnBack',
//           name: (
//             <span className="flex flex-row items-center w-full">
//               <span className="pl-3 pr-1">
//                 <ImArrowLeft2 size={20} />
//               </span>
//               <span className="pl-1 pr-3">Tornar</span>
//             </span>
//           ),
//           link: `/students/grid`,
//         },
//       ],
//     };

//     if (!isNew) {
//       const getApiCall = {
//         url: `students/${params.id}`,
//         method: 'GET',
//         successMessage: null,
//         failureMessage: 'Error de càrrega!',
//       };
//       setApiCallGet(getApiCall);

//       setTitle(titleEdit);
//     } else {
//       setTitle(titleCreate);
//     }
//   }, [params.id, isNew, setApiCallGet, setTitle]);

//   // * Recoger datos API: GET
//   useEffect(() => {
//     if (getApiCallGet.data) {
//       setFields({
//         // ID_ALUMNES: getApiCallGet.data.ID_ALUMNES,
//         CODI_BECARI: getApiCallGet.data.CODI_BECARI,
//         NOM: getApiCallGet.data.NOM,
//         COGNOM: getApiCallGet.data.COGNOM,
//         DNI: getApiCallGet.data.DNI,
//         DATA_NAIXAMENT: getApiCallGet.data.DATA_NAIXAMENT,
//         NUM_SS: getApiCallGet.data.NUM_SS,
//         ID_ESCOLA: getApiCallGet.data.ID_ESCOLA,
//         ID_PROFESSOR: getApiCallGet.data.ID_PROFESSOR,
//         INICI_PRACTIQUES: getApiCallGet.data.INICI_PRACTIQUES,
//         FI_PRACTIQUES: getApiCallGet.data.FI_PRACTIQUES,
//         INICI_CHECK: getApiCallGet.data.INICI_CHECK,
//         FI_CHECK: getApiCallGet.data.FI_CHECK,
//       });
//     }
//   }, [getApiCallGet.data]);

//   // CRUD: POST / PUT
//   const handleSubmit = async (values) => {
//     let url = user.role_id === 1 ? `students/bytchr/${user.id}` : 'students';
//     let method = 'POST';

//     // if (!isNew && user.role_id !== 1) {
//     //   method = 'PUT';
//     //   url += `/${params.id}`;
//     // }
//     if (!isNew && user.role_id !== 1) {
//     method = 'PUT';
//     url += `/${params.id}`;
//   } else if (!isNew && user.role_id === 1) {
//     method = 'PUT';
//     url = `students/bytchr/${params.id}`;
//   }

//     const apiCallPost = {
//       url,
//       method,
//       body: values,
//       messageOk: 'Dades guardades correctament',
//       messageKo: 'Error al guardar les dades',
//       redirectTo: '/students/grid',
//     };
//     await setApiCallPost(apiCallPost);
//   };

// //   const handleSubmit = async (values) => {
// //   let url = 'students'; // URL base
// //   let method = 'POST';  // Método por defecto es POST

// //   // Si no es nuevo y el usuario no es un profesor (rol_id !== 1), usamos PUT para actualizar
// //   if (!isNew && user.role_id !== 1) {
// //     method = 'PUT';
// //     url += `/${params.id}`;
// //   }
// //   // Si no es nuevo y el usuario es profesor (rol_id === 1)
// //   else if (!isNew && user.role_id === 1) {
// //     method = 'PUT';
// //     url = `students/bytchr/${params.id}`;
// //   }

// //   // Aquí es donde añadimos los console.log para depurar
// //   console.log('URL:', url);  // Muestra la URL que se está construyendo
// //   console.log('Method:', method);  // Muestra el método HTTP que se usará (POST o PUT)
// //   console.log('Values:', values);  // Muestra los datos del formulario que se están enviando

// //   // Construimos la llamada API
// //   const apiCallPost = {
// //     url,
// //     method,
// //     body: values,
// //     messageOk: 'Dades guardades correctament',
// //     messageKo: 'Error al guardar les dades',
// //     redirectTo: '/students/grid',
// //   };

// //   // Realizamos la petición
// //   await setApiCallPost(apiCallPost);
// // };


//   // * Mostrar mensaje API: POST / PUT
//   // ! Mostrar error API: POST / PUT
//   useEffect(() => {
//     if (getApiCallPost.isCalled) {
//       const successMessage = (message) =>
//         toast.success(message, {
//           position: toast.POSITION.TOP_CENTER,
//         });

//       const errorMessage = (error) =>
//         toast.error(error, {
//           position: toast.POSITION.TOP_CENTER,
//         });

//       if (getApiCallPost.message) {
//         successMessage(getApiCallPost.message);
//         setLocation(`/students/grid`);
//       }

//       if (getApiCallPost.error) {
//         errorMessage(getApiCallPost.error);
//         setLocation(`/students/grid`);
//       }
//     }
//   }, [getApiCallPost, setLocation]);

//   // CRUD: DELETE
//   const handleDelete = async () => {
//     const apiCallDelete = {
//       url: `students/${params.id}`,
//       method: 'DELETE',
//       messageOk: 'Dades borrades correctament',
//       messageKo: 'Error al borrar les dades',
//       redirectTo: '/students/grid',
//     };
//     await setApiCallDelete(apiCallDelete);
//   };

//   // * Mostrar mensaje API: DELETE
//   // ! Mostrar error API: DELETE
//   useEffect(() => {
//     if (getApiCallDelete.isCalled) {
//       const successMessage = (message) =>
//         toast.success(message, {
//           position: toast.POSITION.TOP_CENTER,
//         });

//       const errorMessage = (error) =>
//         toast.error(error, {
//           position: toast.POSITION.TOP_CENTER,
//         });

//       if (getApiCallDelete.message) {
//         successMessage(getApiCallDelete.message);
//         setLocation(`/students/grid`);
//       }

//       if (getApiCallDelete.error) {
//         errorMessage(getApiCallDelete.error);
//         setLocation(`/students/grid`);
//       }
//     }
//   }, [getApiCallDelete, setLocation]);

//   const renderForm = () => {
//     if (user.role_id === 1) {
//       return (
//         <StudentsFormDetailByTeacher
//           handleSubmit={handleSubmit}
//           handleDelete={handleDelete}
//           fields={fields}
//           isNew={isNew}
//         />
//       );
//     } else {
//       return (
//         <StudentsFormDetails
//           handleSubmit={handleSubmit}
//           handleDelete={handleDelete}
//           fields={fields}
//           isNew={isNew}
//         />
//       );
//     }
//   };

//   return isNew || fields.ID_ALUMNES !== 0 ? (
//     renderForm()
//   ) : (
//     <div>
//       <div className="flex items-center content-center justify-center w-full h-full">
//         <p
//           className="flex flex-col-reverse items-center justify-center h-full font-extrabold text-green-700"
//           title="Carregant..."
//         >
//           Carregant!
//           <img src={loadingIco} alt="Loading..." />
//         </p>
//       </div>
//     </div>
//   );
// }

// StudentsViewDetails.propTypes = {
//   params: PropTypes.any,
// };




import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import useFetch from 'hooks/useFetch';

// COMPONENTS
import StudentsFormDetails from 'forms/Students/StudentsFormDetails';
import StudentsFormDetailByTeacher from 'forms/Students/StudentsFormDetailsByTeacher';
import { toast } from 'react-toastify';

// CONTEXTS
import { useLocation } from 'wouter';
import { TitleContext } from 'contexts/TitleContext';
import { UserContext } from 'contexts/UserContext';

// ASSETS
import { ImArrowLeft2 } from 'react-icons/im';
import loadingIco from 'components/ui/Logo/loading_azul.gif';

export default function StudentsViewDetails({ params }) {
  // Contextos:
  const { user } = useContext(UserContext);
  const [, setLocation] = useLocation();
  const { setTitle } = useContext(TitleContext);

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch();
  const [getApiCallPost, setApiCallPost] = useFetch();
  const [getApiCallDelete, setApiCallDelete] = useFetch();

  // Variables:
  const isNew = params.id === 'new';

  // Estado para los campos del formulario
  const [fields, setFields] = useState({
    // ID_ALUMNES: 0,
    CODI_BECARI: '',
    NOM: '',
    COGNOM: '',
    DNI: '',
    DATA_NAIXAMENT: '',
    NUM_SS: '',
    ID_ESCOLA: '',
    ID_PROFESSOR: '',
    INICI_PRACTIQUES: '', // Añadir campo para el inicio de prácticas
    FI_PRACTIQUES: '', // Añadir campo para el fin de prácticas
    INICI_CHECK: 0,
    FI_CHECK: 0,
  });

   // Estado de carga
  const [loading, setLoading] = useState(true); // <-- Estado de carga

  // CRUD: GET
  useEffect(() => {
    const titleEdit = {
      name: `Modificar Alumne`,
      buttons: [
        {
          id: 'btnBack',
          name: (
            <span className="flex flex-row items-center w-full">
              <span className="pl-3 pr-1">
                <ImArrowLeft2 size={20} />
              </span>
              <span className="pl-1 pr-3">Tornar</span>
            </span>
          ),
          link: `/students/grid`,
        },
      ],
      deleteMTBtn: true,
      mtId: params.id,
    };

    const titleCreate = {
      name: `Registrar Alumne`,
      buttons: [
        {
          id: 'btnBack',
          name: (
            <span className="flex flex-row items-center w-full">
              <span className="pl-3 pr-1">
                <ImArrowLeft2 size={20} />
              </span>
              <span className="pl-1 pr-3">Tornar</span>
            </span>
          ),
          link: `/students/grid`,
        },
      ],
    };

    if (!isNew) {
      const getApiCall = {
        url: `students/${params.id}`,
        method: 'GET',
        successMessage: null,
        failureMessage: 'Error de càrrega!',
      };
      setApiCallGet(getApiCall);

      setTitle(titleEdit);
    } else {
      setTitle(titleCreate);
    }
  }, [params.id, isNew, setApiCallGet, setTitle]);

  // * Recoger datos API: GET
  useEffect(() => {
    if (getApiCallGet.data) {
      setFields({
        // ID_ALUMNES: getApiCallGet.data.ID_ALUMNES,
        CODI_BECARI: getApiCallGet.data.CODI_BECARI,
        NOM: getApiCallGet.data.NOM,
        COGNOM: getApiCallGet.data.COGNOM,
        DNI: getApiCallGet.data.DNI,
        DATA_NAIXAMENT: getApiCallGet.data.DATA_NAIXAMENT,
        NUM_SS: getApiCallGet.data.NUM_SS,
        ID_ESCOLA: getApiCallGet.data.ID_ESCOLA,
        ID_PROFESSOR: getApiCallGet.data.ID_PROFESSOR,
        INICI_PRACTIQUES: getApiCallGet.data.INICI_PRACTIQUES,
        FI_PRACTIQUES: getApiCallGet.data.FI_PRACTIQUES,
        INICI_CHECK: getApiCallGet.data.INICI_CHECK,
        FI_CHECK: getApiCallGet.data.FI_CHECK,
      });
      setLoading(false); // <-- Cambia el estado de carga cuando los datos están disponibles
    }
  }, [getApiCallGet.data]);

  // CRUD: POST / PUT
  const handleSubmit = async (values) => {
    let url = user.role_id === 1 ? `students/bytchr/${user.id}` : 'students';
    let method = 'POST';

    // if (!isNew && user.role_id !== 1) {
    //   method = 'PUT';
    //   url += `/${params.id}`;
    // }
    if (!isNew && user.role_id !== 1) {
    method = 'PUT';
    url += `/${params.id}`;
  } else if (!isNew && user.role_id === 1) {
    method = 'PUT';
    url = `students/bytchr/${params.id}`;
  }

    const apiCallPost = {
      url,
      method,
      body: values,
      messageOk: 'Dades guardades correctament',
      messageKo: 'Error al guardar les dades',
      redirectTo: '/students/grid',
    };
    await setApiCallPost(apiCallPost);
  };

//   const handleSubmit = async (values) => {
//   let url = 'students'; // URL base
//   let method = 'POST';  // Método por defecto es POST

//   // Si no es nuevo y el usuario no es un profesor (rol_id !== 1), usamos PUT para actualizar
//   if (!isNew && user.role_id !== 1) {
//     method = 'PUT';
//     url += `/${params.id}`;
//   }
//   // Si no es nuevo y el usuario es profesor (rol_id === 1)
//   else if (!isNew && user.role_id === 1) {
//     method = 'PUT';
//     url = `students/bytchr/${params.id}`;
//   }

//   // Aquí es donde añadimos los console.log para depurar
//   console.log('URL:', url);  // Muestra la URL que se está construyendo
//   console.log('Method:', method);  // Muestra el método HTTP que se usará (POST o PUT)
//   console.log('Values:', values);  // Muestra los datos del formulario que se están enviando

//   // Construimos la llamada API
//   const apiCallPost = {
//     url,
//     method,
//     body: values,
//     messageOk: 'Dades guardades correctament',
//     messageKo: 'Error al guardar les dades',
//     redirectTo: '/students/grid',
//   };

//   // Realizamos la petición
//   await setApiCallPost(apiCallPost);
// };


  // * Mostrar mensaje API: POST / PUT
  // ! Mostrar error API: POST / PUT
  useEffect(() => {
    if (getApiCallPost.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER,
        });

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        });

      if (getApiCallPost.message) {
        successMessage(getApiCallPost.message);
        setLocation(`/students/grid`);
      }

      if (getApiCallPost.error) {
        errorMessage(getApiCallPost.error);
        setLocation(`/students/grid`);
      }
    }
  }, [getApiCallPost, setLocation]);

  // CRUD: DELETE
  const handleDelete = async () => {
    const apiCallDelete = {
      url: `students/${params.id}`,
      method: 'DELETE',
      messageOk: 'Dades borrades correctament',
      messageKo: 'Error al borrar les dades',
      redirectTo: '/students/grid',
    };
    await setApiCallDelete(apiCallDelete);
  };

  // * Mostrar mensaje API: DELETE
  // ! Mostrar error API: DELETE
  useEffect(() => {
    if (getApiCallDelete.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER,
        });

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        });

      if (getApiCallDelete.message) {
        successMessage(getApiCallDelete.message);
        setLocation(`/students/grid`);
      }

      if (getApiCallDelete.error) {
        errorMessage(getApiCallDelete.error);
        setLocation(`/students/grid`);
      }
    }
  }, [getApiCallDelete, setLocation]);

  const renderForm = () => {
    if (user.role_id === 1) {
      return (
        <StudentsFormDetailByTeacher
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
          fields={fields}
          isNew={isNew}
        />
      );
    } else {
      return (
        <StudentsFormDetails
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
          fields={fields}
          isNew={isNew}
        />
      );
    }
  };

  return isNew || (!loading && fields.ID_ALUMNES !== 0) ? (
    renderForm()
  ) : (
    <div>
      <div className="flex items-center content-center justify-center w-full h-full">
        <p
          className="flex flex-col-reverse items-center justify-center h-full font-extrabold text-green-700"
          title="Carregant..."
        >
          Carregant!
          <img src={loadingIco} alt="Loading..." />
        </p>
      </div>
    </div>
  );
}

StudentsViewDetails.propTypes = {
  params: PropTypes.any,
};
