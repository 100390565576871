// COMPONENTS
import Menu from 'components/layout/Menu/Menu'
import LogoGestinet from 'components/ui/Logo/LogoGestinet'

export default function Sidebar() {
  return (
    // Barra lateral con menú y logo de Gestinet:
    <div className="print:hidden flex-col hidden md:flex w-14 md:w-48 text-white flex-shrink-0 bg-customBlue border-r border-customBorder top-0">
      {/* 'div' necesario para poder hacer esta barra fija en la pantalla y así no desaparezca al hacer scroll: */}
      <div className="h-screen w-14 md:w-48 pt-8 fixed text-white bg-customBlue border-r border-customBorder">
        {/* Menú: */}
        <nav className="md:block px-4 pb-4 md:pb-0 md:overflow-y-auto fixed">
          <Menu device="desktop" />
        </nav>
        {/* Logo: */}
        <LogoGestinet classes="w-28 m-auto mb-6 fixed bottom-0 left-9" />
      </div>
    </div>
  )
}
