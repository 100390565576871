// PropTypes & HOOKS
import PropTypes from 'prop-types'
import useFetch from '../../hooks/useFetch'
import { useContext, useEffect, useState } from 'react'

// COMPONENTS
import MailsFormDetail from 'forms/Mails/MailsFormDetail'
import { toast } from 'react-toastify'

// CONTEXTS
import { useLocation } from 'wouter'
import { TitleContext } from 'contexts/TitleContext'
import { ModalContext } from 'contexts/ModalContext'

// ASSETS
import { ImArrowLeft2 } from 'react-icons/im'
import { UserContext } from 'contexts/UserContext'

export default function MailsViewDetail({ params }) {
  // Fecha actual:
  const currentdate = new Date()
  const datetime =
    String(currentdate.getMonth() + 1).padStart(2, '0') +
    '/' +
    currentdate.getFullYear()

  // Contextos:
  const { setTitle } = useContext(TitleContext)
  const { user } = useContext(UserContext)
  const { openModal } = useContext(ModalContext)

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()

  // Variables
  const isNew = params.id === 'new'

  // ############################################################

  // Título (PUT):
  const titleEdit = {
    name: `Modificar Factura`,
    buttons: [
      {
        id: 'btnBack',
        name: <ImArrowLeft2 size={25} />,
        link: `/mails/grid`
      }
    ],
    deleteBtn: true,
    id: params.id
  }

  // Título (POST):
  const titleCreate = {
    name: `Nueva Factura`,
    buttons: [
      {
        id: 'btnBack',
        name: <ImArrowLeft2 size={25} />,
        link: `/mails/grid`
      }
    ]
  }

  const [fields, setFields] = useState({
    Id: 0,
    DateMail: datetime,
    Subject: null,
    Message: null,
    FileName: null,
    FilePath: null,
    Document: null
  })

  // CRUD: GET
  useEffect(() => {
    if (params.id !== 'new') {
      setTitle(titleEdit)
      const apiCallGet = {
        url: `mails/${params.id}`,
        method: 'GET',
        messageKo: 'Error llegint aquest Mail'
      }

      setApiCallGet(apiCallGet)
    } else {
      setTitle(titleCreate)
    }
  }, [])

  // * Recoger datos API: GET
  useEffect(() => {
    if (getApiCallGet.data) {
      setFields({
        Id: getApiCallGet.data.id,
        DateMail: getApiCallGet.data.DateMail,
        Subject: getApiCallGet.data.Subject,
        Message: getApiCallGet.data.Message,
        FileName: getApiCallGet.data.FileName,
        FilePath: getApiCallGet.data.FilePath,
        Document: null
      })
    }
  }, [getApiCallGet.data])

  // ############################################################

  // CRUD: POST / PUT
  const handleSubmit = async (values, e, isSendMail) => {
    const formData = new FormData()
    const files = e.current.files

    let url = `mails`
    let method = 'POST'

    if (params.id !== 'new') {
      method = 'PUT'
      url += `/${params.id}`
    } else {
      values.Id = 0
    }

    if (files) {
      if (files && files.length > 1) {
        Array.from(files).forEach((file) => {
          formData.append('document', file)
        })
      } else if (files) {
        formData.append('document', files[0])
      }
    }

    formData.append('id', values.Id)
    formData.append('DateMail', values.DateMail)
    formData.append('Subject', values.Subject)
    formData.append('Message', values.Message)
    formData.append('FileName', values.FileName)
    formData.append('FilePath', values.FilePath)
    formData.append('UserMail', user.email)

    const apiCallPost = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      url,
      method,
      formData
    }
    await setApiCallPost(apiCallPost)
  }

  // * Mostrar mensaje API: POST / PUT
  // ! Mostrar error API: POST / PUT
  useEffect(() => {
    if (getApiCallPost.isCalled) {
      handleLoadingModal(
        getApiCallPost.loading,
        '/mails/grid',
        'Cargando archivos PDF... ¡En un par de minutos recibirá una notificación por correo!',
        getApiCallPost.error,
        getApiCallPost.message,
        false
      )

      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallPost.message) {
        successMessage(getApiCallPost.message)
      }

      if (getApiCallPost.error) {
        errorMessage(getApiCallPost.error)
      }
    }
  }, [getApiCallPost.loading])

  // ############################################################

  // Modal cargando cambios:
  const handleLoadingModal = (
    isLoading,
    url,
    message,
    apiCallError,
    apiCallMessage,
    isSync
  ) => {
    const modalData = {
      isLoading,
      url,
      message,
      isSync,
      apiCallError,
      apiCallMessage,
      type: 'LOADING'
    }

    openModal(modalData)
  }

  // ############################################################

  return (
    <>
      <div className="w-full h-full bg-white">
        <div className="w-full mb-3 p-4 bg-gray-200 border-2 border-blue-600 rounded">
          <MailsFormDetail
            handleSubmit={handleSubmit}
            isNew={isNew}
            fields={fields}
          />
        </div>
      </div>
    </>
  )
}

MailsViewDetail.propTypes = {
  params: PropTypes.any
}
