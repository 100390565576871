import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './pages/App'
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// CONTEXTS
import { UserContextProvider } from 'contexts/UserContext'
import { TitleContextProvider } from 'contexts/TitleContext'
import ModalContextProvider, { ModalContext } from 'contexts/ModalContext'
import DeleteModal from 'components/ui/Modals/DeleteModal'
import LoadingModal from 'components/ui/Modals/LoadingModal'
import SendMailModal from 'components/ui/Modals/SendMailModal'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <UserContextProvider>
      <TitleContextProvider>
        <ModalContextProvider>
          <ModalContext.Consumer>
            {({ isModalOpen, openModal, closeModal, getModalData }) => (
              <>
                <App />
                {isModalOpen &&
                  {
                    DELETE: <DeleteModal />,
                    SEND: <SendMailModal />,
                    LOADING: <LoadingModal />
                  }[getModalData.type]}
              </>
            )}
          </ModalContext.Consumer>
        </ModalContextProvider>
      </TitleContextProvider>
    </UserContextProvider>
  </React.StrictMode>
)