// REACT HOOKS
import { useContext, useEffect } from 'react'

// CONTEXTS
import { UserContext } from 'contexts/UserContext'

export default function Logout() {
  // Contextos:
  const { setUser } = useContext(UserContext)

  useEffect(() => {
    setUser({})
  }, [])

  return <h2>¿Cerrando sesión!</h2>
}
