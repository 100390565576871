// REACT HOOKS
import { useContext, useEffect } from 'react'
import useFetch from 'hooks/useFetch'

// COMPONENTS
import SinglesCompGrid from 'components/Singles/SinglesCompGrid'

// CONTEXTS
import { TitleContext } from 'contexts/TitleContext'
import { useLocation } from 'wouter'

// ASSETS
import loadingIco from 'components/ui/Logo/loading_azul.gif'

export default function SinglesViewGrid() {
  // Contextos:
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch()

  // ############################################################

  // CRUD: GET
  useEffect(() => {
    const apiCallGet = {
      url: `singles`,
      method: 'GET',
      messageKo: '¡Error cargando Clientes!'
    }
    setApiCallGet(apiCallGet)
  }, [])

  // * Recoger datos API: GET
  useEffect(() => {
    const title = {
      name: 'Clientes',
      buttons: [
        {
          name: 'Registrar Cliente',
          link: `/singles/new`
        }
      ],
      button: 'print',
      export: true,
      csvData:
        getApiCallGet.data !== null &&
        getApiCallGet.data !== undefined &&
        getApiCallGet.data[0] !== null
          ? getApiCallGet.data
          : null
    }
    setTitle(title)
  }, [getApiCallGet.data])

  // ############################################################

  // 'handleClick()' para cada item en una lista, al pulsar un item nos llevará a la ruta especificada en el 'setLocation':
  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(
      `/singles/${item.Singles_IdInterno ? item.Singles_IdInterno : 'new'}`
    )
  }

  // ############################################################

  return getApiCallGet?.data ? (
    <>
      <div
        className={`w-full ${
          getApiCallGet.data.length > 0 ? 'block' : 'hidden'
        }`}
      >
        <div className="flex flex-col">
          <SinglesCompGrid
            items={getApiCallGet.data}
            handleClick={handleClick}
          />
        </div>
      </div>

      <div
        className={`w-full ${
          getApiCallGet.data.length > 0 ? 'hidden' : 'block'
        }`}
      >
        <div className="flex flex-col">
          <div
            className="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <svg
                  className="fill-current h-6 w-6 text-red-500 mr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              <div>
                <p className="font-bold">No hay clientes registrados</p>
                <p className="text-sm">
                  ¡Regístra un cliente pulsando el botón{' '}
                  <b>
                    <i>Registrar Cliente</i>
                  </b>
                  !
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div>
      <div className="h-full w-full flex justify-center items-center content-center">
        <p
          className="h-full text-blue-700 font-extrabold flex justify-center flex-col-reverse items-center"
          title="Cargando..."
        >
          ¡Cargando!
          <div className="mt-16 mb-8" style={{ width: '75px', height: '75px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
              <style>
                {`
                  @keyframes zoomInOut {
                    0%, 100%, 5%, 95% {
                      transform: translate(50px, 50px) scale(0);
                    }
                    20%, 80% {
                      transform: translate(10px, 10px) scale(.8);
                    }
                  }
                  
                  @keyframes spin {
                    from {
                      transform: rotate(0);
                    }
                    to {
                      transform: rotate(360deg);
                    }
                  }
                `}
              </style>

              <defs>
                <mask id="Mask">
                  <circle cx="50" cy="50" r="50" fill="#fff"></circle>
                  <circle
                    cx="50"
                    cy="50"
                    r="50"
                    style={{
                      animationName: 'zoomInOut',
                      animationDuration: '5.2s',
                      animationIterationCount: 'infinite',
                      animationTimingFunction: 'linear'
                    }}
                  ></circle>
                </mask>
              </defs>

              <g
                style={{
                  animationName: 'spin',
                  animationDuration: '1.3s',
                  transformOrigin: 'center',
                  animationIterationCount: 'infinite',
                  animationTimingFunction: 'linear'
                }}
                fill="#1C69D4"
                mask="url(#Mask)"
              >
                <path d="M0 0h50v50H0zM50 50h50v50H50z"></path>
              </g>
            </svg>
          </div>
        </p>
      </div>
    </div>
  )
}
